import React from "react"
import EditEmployeeForm from "../../Components/Forms/EditEmployeeForm"

function EditEmployeeInformation () {
  return (
    <>
      <h1>Edit Employee Information</h1>
      <EditEmployeeForm />
    </>
  )
}

export default EditEmployeeInformation

