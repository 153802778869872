import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { Modal, CircularProgress, Divider } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { fetchEmpContractDetails } from "../../Actions/commonController"
import moment from "moment"

export default function ContractDetailsModal({ open, onClose, empId }) {
  const [loader, setLoader] = useState(false)
  const [contractDetails, setContractDetails] = useState(null)

  const fetchData = async () => {
    try {
      let APIData = {
        empid: empId,
      }
      if (empId) {
        setLoader(true)
        let { data, status } = await fetchEmpContractDetails(APIData)
        if (status === "success") {
          const formattedContracts = data[0]?.contractupdate
            ? JSON.parse(data[0].contractupdate).map((contract) => ({
                ...contract,
              }))
            : []

          setContractDetails({ ...data[0], contractupdate: formattedContracts })
        }
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
      console.error("Error fetching contract details data:", error)
    }
  }

  useEffect(() => {
    if (open) fetchData()
  }, [empId, open])

  const formatDate = (date) => {
    return moment(date).format("Do MMMM, YYYY")
  }

  return (
    <Modal
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      open={open}
      onClose={onClose}
      className="modal"
      keepMounted
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 id="keep-mounted-modal-title" className="name">
            Contract Details
          </h2>
          <button
            type="button"
            onClick={onClose}
            className="close-icon"
          >
            <CloseIcon />
          </button>
        </div>
        <Divider className="divider"/>
        <div className="modalBody">
          {loader ? (
            <CircularProgress size={24} style={{ color: "black" }} />
          ) : contractDetails?.contractupdate?.length > 0 ? (
            contractDetails.contractupdate.map((contract, index) => (
              <div key={index} className="division">
                <h3 className="centered-heading">Contract No. {index + 1}</h3>
                <p id="keep-mounted-modal-description" className="field">
                  Contract Date:
                </p>
                <h4>{formatDate(contract.date)}</h4>
                <p id="keep-mounted-modal-description" className="field">
                  Basic Salary:
                </p>
                <h4>{contract.basic}</h4>
                <p id="keep-mounted-modal-description" className="field">
                  Housing Allowance:
                </p>
                <h4>{contract.housing}</h4>
                <p id="keep-mounted-modal-description" className="field">
                  Transport Allowance:
                </p>
                <h4>{contract.transport}</h4>
                <p id="keep-mounted-modal-description" className="field">
                  Food Allowance:
                </p>
                <h4>{contract.food}</h4>
                <p id="keep-mounted-modal-description" className="field">
                  Mobile Allowance:
                </p>
                <h4>{contract.mobile}</h4>
                <p id="keep-mounted-modal-description" className="field">
                  Other Allowance:
                </p>
                <h4>{contract.allowance}</h4>
                <p id="keep-mounted-modal-description" className="field">
                  Total Salary:
                </p>
                <h4>{contract.total}</h4>
              </div>
            ))
          ) : (
            <p>No contract updates available.</p>
          )}
        </div>
      </div>
    </Modal>
  )
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props
  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  )
})

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
}