import React from 'react'
import Sidebar from '../../Components/sideBar'

function AttendanceReports() {
  return (
    <h1>
      Attendance Reports
    </h1>
  )
}

export default AttendanceReports
