import React, { useEffect, useState } from "react"
import {
    Modal,
    Box,
    Typography,
    CircularProgress,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
} from "@mui/material"
import {
    fetchTrackLeaves,
} from "../../Actions/commonController"
import PaginationTable from "../Pagination"
import {FormatDate} from "../Utilities/DateFormatter"

const columns = [
    { id: "type", label: "LEAVE TYPE", minWidth: 125 },
    { id: "startdate", label: "START DATE", minWidth: 125 },
    { id: "enddate", label: "END DATE", minWidth: 125 },
    { id: "duration", label: "DURATION", minWidth: 80 },
    { id: "reason", label: "REASON", minWidth: 125 },
    { id: "status", label: "STATUS", minWidth: 100 },
    { id: "createdAt", label: "CREATED AT", minWidth: 125 },
]

export default function TrackLeavesModal({ open, onClose, empId }) {
    const [page, setPage] = useState(1)
    const [quantity, setQuantity] = useState(10)
    const [totalItems, setTotalItems] = useState(0)
    const [leaveData, setLeaveData] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchEmpLeaveData = async () => {
        setLoading(true)
        try {
            let APIData = {
                empid: empId
            }
            let { data, status } = await fetchTrackLeaves(APIData)
            if (status === "success") {
                setLeaveData(data)
                setTotalItems(data.length)
            } else {
                console.error("Error fetching leave applications:", status)
            }
        } catch (error) {
            console.error("Error fetching leave applications:", error)
        }
        setLoading(false)
    }

    const handlePageClick = async (newPage) => {
        setPage(newPage)
    }

    const handleItemsPerPageChange = async (newRowsPerPage) => {
        setQuantity(newRowsPerPage)
        setPage(1)
    }

    useEffect(() => {
        if (empId) {
            fetchEmpLeaveData()
        }
    }, [empId])

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                className="track-leaves-modal"
                sx={{
                    width: "80%",
                    maxWidth: "800px",
                    backgroundColor: "white",
                    margin: "auto",
                    padding: "20px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    position: "absolute",
                    maxHeight: "90vh",
                    overflowY: "auto",
                    borderRadius: "10px",
                    boxShadow: 24,
                }}
            >
                <Typography id="modal-title" variant="h6" component="h2">
                    Leaves History
                </Typography>
                {loading ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "300px",
                        }}
                    >
                        <CircularProgress size={50} />
                    </Box>
                ) : (
                    <>
                        {/* <Typography variant="subtitle1" sx={{ mt: 2 }}>
                            <strong>Leave Balance:</strong>
                            <p>Annual: {leaveBalance.annual}</p>
                            <p>Sick: {leaveBalance.sick}</p>
                            <p>Maternity: {leaveBalance.maternity}</p>
                            <p>Unpaid: {leaveBalance.unpaid}</p>
                        </Typography> */}
                        <TableContainer sx={{ paddingTop: "20px", overflowY: "auto" }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align="center"
                                                style={{ minWidth: column.minWidth, fontWeight: "bold", fontSize: "12px" }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {leaveData && leaveData.length > 0 ? (
                                        leaveData.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center">{item.type}</TableCell>
                                                <TableCell align="center">{FormatDate(item.startdate)}</TableCell>
                                                <TableCell align="center">{FormatDate(item.enddate)}</TableCell>
                                                <TableCell align="center">{item.duration}</TableCell>
                                                <TableCell align="center">{item.reason}</TableCell>
                                                <TableCell align="center">{item.status}</TableCell>
                                                <TableCell align="center">{FormatDate(item.createdAt)}</TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={7} align="center">
                                                No leave data available
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <PaginationTable
                            totalItems={totalItems}
                            itemsPerPage={quantity}
                            onPageChange={handlePageClick}
                            onItemsPerPageChange={handleItemsPerPageChange}
                        />
                    </>


                )}
            </Box>
        </Modal>
    )
}