import React, { useEffect, useState } from "react"
import {
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    CircularProgress,
} from "@mui/material"
import SearchBar from "../../SearchBar"
import PaginationTable from "../../Pagination"
import {
    fetchEmployeesUnderManager,
    fetchEmpsUnderManagersCount,
} from "../../../Actions/commonController"
import { useAuth } from "../../../Context/authContext"
import TrackLeavesModal from "../../Modals/TrackLeavesModal"
import SalaryCertificateHistoryModal from "../../Modals/SalaryCertificateHistoryModal"

const columns = [
    { id: "Id", label: "EMPID", minWidth: 40 },
    { id: "name", label: "NAME", minWidth: 80 },
    { id: "trackLeaves", label: "LEAVES HISTORY", minWidth: 80 },
    { id: "salaryCertificateHistory", label: "SALARY CERTIFICATE HISTORY", minWidth: 80 },
]

const actionButtonStyle = {
    margin: "4px",
    fontSize: "10px",
}

export default function ManageEmployeeTable() {
    const { eid } = useAuth()
    const [page, setPage] = useState(1)
    const [quantity, setQuantity] = useState(10)
    const [totalItems, setTotalItems] = useState(0)
    const [selectedData, setSelectedData] = useState(null)
    const [data, setData] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [detailsModalOpen, setDetailsModalOpen] = useState("")
    const [loader, setLoader] = useState(false)
    const [initialLoad, setInitialLoad] = useState(false)
    const [leaveModalOpen, setLeaveModalOpen] = useState(false)
    const [salaryHistoryModalOpen, setSalaryHistoryModalOpen] = useState(false)

    const handleSearch = async (searchData) => {
        setSearchTerm(searchData)
    }

    const initialization = async () => {
        try {
            let APIData = {
                search: searchTerm,
                empId: eid,
            }
            setLoader(true)
            let { status, data } = await fetchEmpsUnderManagersCount(APIData)

            if (status === "success") {
                setTotalItems(data)
                loadData()
            }
        } catch (err) {
            console.error("Error in loadData function", err)
        }
    }

    const loadData = async () => {
        try {
            let APIData = {
                empId: eid,
                search: searchTerm,
                quantity: quantity,
                page: page
            }
            setLoader(true)
            let { data, status } = await fetchEmployeesUnderManager(APIData)

            if (status === "success") {
                setData(data)
            } else {
                console.error("Error fetching employee records:", status)
            }
        } catch (error) {
            console.error("Error in loadData function:", error)
        }
        setLoader(false)
    }

    const handlePageClick = async (newPage) => {
        setPage(newPage)
    }

    const handleItemsPerPageChange = async (newRowsPerPage) => {
        setQuantity(newRowsPerPage)
        setPage(1)
    }

    const handleButtonClick = (columnId, empId) => {
        setSelectedData(empId)
        if (columnId === "trackLeaves") {
            setLeaveModalOpen(true)
        } else {
            setSalaryHistoryModalOpen(true)
        }
    }

    const handleCloseModal = () => {
        setSelectedData(null)
        setDetailsModalOpen("")
        setLeaveModalOpen(false)
        setSalaryHistoryModalOpen(false)
    }

    useEffect(() => {
        if (initialLoad) loadData()
        setInitialLoad(true)
    }, [page, quantity, eid])

    useEffect(() => {
        initialization()
    }, [searchTerm])

    return (
        <Paper className="pagePaper">
            <span className="info">
                <h5>Manage Employees</h5>
                <SearchBar searchText="Search by name" onSearch={handleSearch} />
            </span>

            <div className="row" style={{
                paddingTop: "60px",
            }}>
                <div className="col-md-12">
                    <TableContainer
                        sx={{
                            overflowY: "auto",
                            maxHeight: "500px"
                        }}
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            className="tableHeader"
                                            key={column.id}
                                            align="center"
                                            style={{
                                                minWidth: column.minWidth,
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {
                                loader ?
                                    <TableRow>
                                        <TableCell colSpan={11} style={{ padding: "30px 0", textAlign: "center" }}>
                                            <CircularProgress size={100} />
                                        </TableCell>
                                    </TableRow>
                                    : data && data.length > 0 ? data.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center" className="p-0">{item.empid}</TableCell>
                                            <TableCell align="center" className="p-0">{item.name}</TableCell>
                                            {columns.slice(2, columns.length).map((column) => (
                                                <TableCell className="p-0" key={column.id} align="center">
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => handleButtonClick(column.id, item.empid)}
                                                        style={{
                                                            ...actionButtonStyle,
                                                            backgroundColor: column.id === "salaryCertificateHistory" ? "#2596BE" : "#2596BE",
                                                            fontSize: "10px",
                                                        }}
                                                    >
                                                        View
                                                    </Button>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                        :
                                        <TableRow>
                                            <TableCell colSpan={11} style={{ padding: "30px 0", textAlign: "center" }}>
                                                No data found
                                            </TableCell>
                                        </TableRow>
                            }
                        </Table>
                    </TableContainer>

                    <PaginationTable
                        totalItems={totalItems}
                        itemsPerPage={quantity}
                        onPageChange={handlePageClick}
                        onItemsPerPageChange={handleItemsPerPageChange}
                    />

                </div>
            </div>
            <TrackLeavesModal
                open={leaveModalOpen}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <SalaryCertificateHistoryModal
                open={salaryHistoryModalOpen}
                onClose={handleCloseModal}
                empId={selectedData}
            />
        </Paper>
    )
}