import React from 'react'
import Sidebar from '../../Components/sideBar'

function JobOpenings() {
  return (
    <h1>
      Job Openings
    </h1>
  )
}

export default JobOpenings
