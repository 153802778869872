import React, { useState } from "react"
import SearchIcon from "@mui/icons-material/Search"

const SearchBar = ({ onSearch, searchText, searchType }) => {
  const [searchTerm, setSearchTerm] = useState("")

  const handleChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleSearch = async () => {
    onSearch(searchTerm, searchType)
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch()
    }
  }

  return (
    <div className="searchbar" style={{ position: "relative", zIndex: 1000, alignSelf: "center", paddingLeft: "10px" }}>
      <input
        type="text"
        placeholder={searchText}
        value={searchTerm}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        style={{
          border: "none",
          borderBottom: "1px solid transparent",
          outline: "none",
          padding: "15px 20px 15px 15px",
          boxSizing: "border-box",
          transition: "border-color 0.3s ease",
          backgroundColor: "#EAF1F3",
          boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
          borderRadius: "23.5px"
        }}
      />
      <SearchIcon
        onClick={handleSearch}
        style={{
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          color: "#2596BE",
          cursor: "pointer",
        }}
      />
    </div>
  )
}

export default SearchBar
