import React, { useState, useEffect } from "react"
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Button,
  CircularProgress,
  Modal,
  Typography,
  Box,
  Stack,
  Chip,
  TextField,
  DialogActions,
  Popover,
} from "@mui/material"
import {
  fetchSalaryCertificates,
  handleSalaryCert,
  downloadSalaryCertificate
} from "../../../Actions/commonController"
import PaginationTable from "../../Pagination"
import { useAuth } from "../../../Context/authContext"
import { toast } from "react-toastify"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty"
import DownloadIcon from "@mui/icons-material/Download"
import { FormatDate } from "../../Utilities/DateFormatter"
import SearchBar from "../../SearchBar"
import * as toWords from "number-to-words"
import { DownloadSalaryCertif } from "../../Utilities/DownloadSalaryCertif"

export default function SalaryCertificateRequestsTable() {
  const [page, setPage] = useState(1)
  const [quantity, setQuantity] = useState(10)
  const [totalItems, setTotalItems] = useState(0)
  const [data, setData] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedRequest, setSelectedRequest] = useState(null)
  const [selectedReason, setSelectedReason] = useState("")
  const [reasonModalOpen, setReasonModalOpen] = useState(false)
  const [loader, setLoader] = useState(false)
  const [actionLoader, setActionLoader] = useState(false)
  const [downloadingPDF, setDownloadingPDF] = useState(null)
  const [downloadingWord, setDownloadingWord] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [trackerModalOpen, setTrackerModalOpen] = useState(false)
  const [onConfirm, setOnConfirm] = useState("")
  const [actionType, setActionType] = useState("")
  const [rejectReason, setRejectReason] = useState("")
  const { eid } = useAuth()
  const [statusFilter, setStatusFilter] = useState("all")
  const [searchTerm, setSearchTerm] = useState("")
  const [rejectModalOpen, setRejectModalOpen] = useState(false)
  const [selectedRejectInfo, setSelectedRejectInfo] = useState({
    rejectedByName: "",
    rejectedreason: "",
  })
  const [trackerData, setTrackerData] = useState({
    pending: [],
    accepted: [],
    rejected: [],
  })

  const fetchRequests = async () => {
    try {
      let APIData = {
        empid: eid,
        quantity: quantity,
        page: page,
        status: statusFilter,
        search: searchTerm,
      }
      setLoader(true)
      let { status, data } = await fetchSalaryCertificates(APIData)
      if (status === "success") {
        setData(data)
        setTotalItems(data.length)
      } else {
        console.error("Error fetching salary certificates", status)
      }
    } catch (error) {
      console.error("Error in fetchRequests function", error)
    }
    setLoader(false)
  }

  const handleOption = async () => {
    try {
      setActionLoader(true)
      let APIData = {
        action: actionType.toLowerCase(),
        id: onConfirm,
        empid: eid,
        reason: rejectReason,
      }
      const { status } = await handleSalaryCert(APIData)
      if (status === "success") {
        setDialogOpen(false)
        setRejectReason("")
        toast.success(`Request ${actionType.toLowerCase()} successfully!`)
        fetchRequests()
      }
    } catch (err) {
      toast.error(err.message)
    }
    setActionLoader(false)
    setReasonModalOpen(false)
    setOnConfirm("")
  }

  const formatDateWithSuffix = (date) => {
    const day = date.getDate()
    const month = date.toLocaleString('default', { month: 'long' })
    const year = date.getFullYear()

    const suffix = (d) => {
      if (d > 3 && d < 21) return "th"
      switch (d % 10) {
        case 1: return "st"
        case 2: return "nd"
        case 3: return "rd"
        default: return "th"
      }
    }

    return `${day}${suffix(day)} ${month}, ${year}`
  }

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  //add email logic here
  const handleDownloadAndSend = async (empid, gender, sponsorname, format) => {
    try {
      if (format === 'pdf') {
        setDownloadingPDF(empid)
      } else if (format === 'word') {
        // setDownloadingWord(empid)
        toast.error("This feature is not available right now!")
        return
      }

      const { status, data } = await downloadSalaryCertificate({ empid })

      if (status === "success") {
        const { dateofjoin, totalsalary, ...restData } = data

        const dateofjoinFormatted = new Date(dateofjoin).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: '2-digit'
        })

        const today = formatDateWithSuffix(new Date())
        const title = gender === "Female" ? "Ms." : "Mr."
        const pronoun = gender === "Female" ? "her" : "his"
        const salaryInWords = totalsalary !== null ? toWords.toWords(totalsalary) : "-"

        const employee = {
          ...restData,
          dateofjoin: dateofjoinFormatted,
          totalsalary: totalsalary !== null ? totalsalary : "N/A",
        }

        DownloadSalaryCertif(employee, today, title, pronoun, salaryInWords, format)

        toast.success("Salary Certificate downloaded successfully!")
      } else {
        toast.error("Failed to download salary certificate.")
      }
    } catch (error) {
      console.error("Error downloading salary certificate:", error)
      toast.error("Failed to download salary certificate.")
    } finally {
      if (format === 'pdf') {
        setDownloadingPDF(null)
      } else if (format === 'word') {
        setDownloadingWord(null)
      }
    }
  }

  const handleSearch = async (term) => {
    setSearchTerm(term)
  }

  const handleFilter = (status) => {
    setStatusFilter(status)
    setPage(1)
  }

  const handlePageClick = (newPage) => {
    setPage(newPage)
  }

  const handleItemsPerPageChange = async (newRowsPerPage) => {
    setQuantity(newRowsPerPage)
    setPage(1)
  }

  const handleRejectedInfo = (rejectedByName, rejectedreason) => {
    setSelectedRejectInfo({ rejectedByName, rejectedreason })
    setRejectModalOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setSelectedRequest(null)
  }

  const handleCloseModal = () => {
    setReasonModalOpen(false)
    setTrackerModalOpen(false)
    setRejectModalOpen(false)
    setOnConfirm("")
  }

  const handleTrackerButtonClick = (leave) => {
    const tracker = {
      pending: leave.pendingNames ? leave.pendingNames.split(",") : [],
      accepted: leave.acceptedNames ? leave.acceptedNames.split(",") : [],
      rejected: leave.rejectedNames ? leave.rejectedNames.split(",") : [],
    }
    setTrackerData(tracker)
    setTrackerModalOpen(true)
  }

  useEffect(() => {
    fetchRequests()
  }, [eid, statusFilter, page, quantity, searchTerm])

  const renderTracker = () => {
    const steps = [
      ...trackerData.accepted.map((name) => ({ name, status: "accepted" })),
      ...trackerData.pending.map((name) => ({ name, status: "pending" })),
      ...trackerData.rejected.map((name) => ({ name, status: "rejected" })),
    ]

    return (
      <Box display="flex" flexDirection="column" alignItems="center" padding="20px">
        {steps.map((step, index) => (
          <Box key={index} display="flex" flexDirection="row" width="100%" alignItems="flex-start">
            <Box position="relative" display="flex" flexDirection="column" alignItems="center" mr={2}>
              <Box
                width={20}
                height={20}
                borderRadius="50%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgcolor={
                  step.status === "accepted" ? "green" : step.status === "rejected" ? "red" : "gray"
                }
                color="white"
                zIndex={1}
              >
                {step.status === "accepted" ? (
                  <CheckCircleIcon fontSize="small" />
                ) : (
                  <HourglassEmptyIcon fontSize="12px" />
                )}
              </Box>
              {index < steps.length - 1 && (
                <Box
                  width={2}
                  height={30}
                  bgcolor={
                    steps[index + 1].status === "accepted"
                      ? "green"
                      : steps[index + 1].status === "rejected"
                        ? "red"
                        : "gray"
                  }
                  mt={1}
                  mb={1}
                />
              )}
            </Box>
            <Typography>{step.name}</Typography>
          </Box>
        ))}
      </Box>
    )
  }

  const handleReasonButton = (reason) => {
    setSelectedReason(reason)
    setReasonModalOpen(true)
  }

  const handleAction = (id, type) => {
    setOnConfirm(id)
    setActionType(type)
    setDialogOpen(true)
  }

  const chipColor = (status) => {
    switch (status.toLowerCase()) {
      case "pending":
        return "primary"
      case "cancelled":
        return "warning"
      case "rejected":
        return "error"
      case "accepted":
        return "success"
      default:
        return "default"
    }
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }

  return (
    <Paper className="pagePaper">
      <span className="info">
        <h5>Salary Certificate Requests</h5>
        <SearchBar searchText="Search by name..." onSearch={handleSearch} searchType="name" />
      </span>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "30px" }}>
        <Button
          variant="contained"
          onClick={() => handleFilter("all")}
          sx={{
            backgroundColor: statusFilter === "all" ? "#2596BE" : "transparent",
            color: statusFilter === "all" ? "white" : "#2596BE",
            marginRight: "10px",
            "&:hover": {
              backgroundColor: "#2596BE",
              color: "white",
            },
          }}
        >
          All
        </Button>

        <Button
          variant="contained"
          onClick={() => handleFilter("Pending")}
          sx={{
            backgroundColor: statusFilter === "Pending" ? "#2596BE" : "transparent",
            color: statusFilter === "Pending" ? "white" : "#2596BE",
            marginRight: "10px",
            "&:hover": {
              backgroundColor: "#2596BE",
              color: "white",
            },
          }}
        >
          Pending
        </Button>
        <Button
          variant="contained"
          onClick={() => handleFilter("Accepted")}
          sx={{
            backgroundColor: statusFilter === "Accepted" ? "#2596BE" : "transparent",
            color: statusFilter === "Accepted" ? "white" : "#2596BE",
            marginRight: "10px",
            "&:hover": {
              backgroundColor: "#2596BE",
              color: "white",
            },
          }}
        >
          Accepted
        </Button>
        <Button
          variant="contained"
          onClick={() => handleFilter("Rejected")}
          sx={{
            backgroundColor: statusFilter === "Rejected" ? "#2596BE" : "transparent",
            color: statusFilter === "Rejected" ? "white" : "#2596BE",
            marginRight: "10px",
            "&:hover": {
              backgroundColor: "#2596BE",
              color: "white",
            },
          }}
        >
          Rejected
        </Button>
        <Button
          variant="contained"
          onClick={() => handleFilter("Cancelled")}
          sx={{
            backgroundColor: statusFilter === "Cancelled" ? "#2596BE" : "transparent",
            color: statusFilter === "Cancelled" ? "white" : "#2596BE",
            "&:hover": {
              backgroundColor: "#2596BE",
              color: "white",
            },
          }}
        >
          Cancelled
        </Button>
      </div>
      <div className="row" style={{
        paddingTop: "60px",
      }}>
        <div className="col-md-12">
          <TableContainer
            sx={{
              overflowY: "auto",
              maxHeight: "500px"
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell key={"EMPID"} align="center" className="tableHeader" width={40}>EMPID</TableCell>
                  <TableCell key={"NAME"} align="center" className="tableHeader" width={150}>NAME</TableCell>
                  <TableCell key={"EMAIL"} align="center" className="tableHeader" width={200}>EMAIL</TableCell>
                  <TableCell key={"REASON"} align="center" className="tableHeader" width={80}>REASON</TableCell>
                  <TableCell key={"STATUS"} align="center" className="tableHeader" width={80}>STATUS</TableCell>
                  <TableCell key={"REQUEST DATE"} align="center" className="tableHeader" width={150}>REQUEST DATE</TableCell>
                  <TableCell key={"ACTIONS"} align="center" className="tableHeader" width={200}>ACTIONS</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loader ? (
                  <TableRow>
                    <TableCell colSpan={11} style={{ padding: "30px 0", textAlign: "center" }}>
                      <CircularProgress size={100} />
                    </TableCell>
                  </TableRow>
                ) : data && data.length > 0 ? (
                  data.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell align="center" className="p-0">{item.empid}</TableCell>
                      <TableCell align="center" className="p-0">{item.name}</TableCell>
                      <TableCell align="center" className="p-0">{item.email}</TableCell>
                      <TableCell align="center" className="p-0">
                        <Button variant="contained" className="tableButton" onClick={() => { handleReasonButton(item.reason) }}>
                          View
                        </Button>
                      </TableCell>
                      <TableCell align="center" className="p-0">
                        <Stack direction="row" spacing={1}>
                          <Chip label={capitalizeFirstLetter(item.status)} color={chipColor(item.status)} />
                        </Stack>
                      </TableCell>
                      <TableCell align="center" className="p-0">{FormatDate(item.createdat)}</TableCell>
                      <TableCell align="center" className="p-0">
                        {item.status === "Accepted" ? (
                          <>
                            <Button
                              variant="contained"
                              className="btn download"
                              onClick={handleDownloadClick}
                            >
                              {downloadingPDF === item.empid || downloadingWord === item.empid ? (
                                <CircularProgress size={16} sx={{ color: "white" }} />
                              ) : (
                                <>
                                  <DownloadIcon style={{ fontSize: "20px" }} />
                                  Salary Certificate
                                </>
                              )}
                            </Button>
                            <Popover
                              open={Boolean(anchorEl)}
                              anchorEl={anchorEl}
                              onClose={handlePopoverClose}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            >
                              <Stack className="salaryCertif">
                                <Button
                                  onClick={() => handleDownloadAndSend(item.empid, item.gender, item.sponsorname, 'pdf')}
                                  sx={{ justifyContent: 'flex-start', padding: '10px 20px' }}
                                >
                                  Download as PDF
                                </Button>
                                <Button
                                  onClick={() => handleDownloadAndSend(item.empid, item.gender, item.sponsorname, 'word')}
                                  sx={{ justifyContent: 'flex-start', padding: '10px 20px' }}
                                >
                                  Download as Word
                                </Button>
                              </Stack>
                            </Popover>
                          </>
                        ) : item.status === "Pending" ? (
                          <>
                            <Button variant="contained" className="btn success" onClick={() => handleAction(item.id, "Accepted")}>
                              Accept
                            </Button>
                            <Button variant="contained" className="btn rejected" onClick={() => handleAction(item.id, "Rejected")}>
                              Reject
                            </Button>
                            <Button variant="contained" className="btn tracker" onClick={() => handleTrackerButtonClick(item)}>
                              Tracker
                            </Button>
                          </>
                        ) : item.status === "Cancelled" ? (
                          "-"
                        ) : item.status === "Rejected" ? (
                          <Button variant="contained" className="btn rjct" onClick={() => handleRejectedInfo(item.rejectedByName, item.rejectedreason)}>
                            Info
                          </Button>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={11} style={{ padding: "30px 0", textAlign: "center" }}>
                      No data found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Modal
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="confirmation-modal-title"
        aria-describedby="confirmation-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            p: 4,
            padding: "20px",
          }}
        >
          <Typography id="confirmation-modal-title" variant="h6" component="h2">
            {actionType === "Accepted"
              ? "Issue Salary Certificate"
              : "Reject Issue Salary Certificate"
            }
          </Typography>
          <Typography id="confirmation-modal-description" sx={{ my: 2, color: "gray" }}>
            {actionType === "Accepted"
              ? "Are you sure you want to accept the issuance of certificate request?"
              : "Are you sure you want to reject the issuance of certificate request?"}
          </Typography>
          {actionType === "Rejected" && (
            <TextField
              sx={{ marginTop: "25px" }}
              margin="dense"
              id="reason"
              label="Reason"
              type="text"
              multiline
              rows={4}
              fullWidth
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
            />
          )}
          <DialogActions sx={{ display: "flex", justifyContent: "space-between", padding: "10px 0px 0px 0px" }}>
            <Button
              sx={{ backgroundColor: "red", color: "white", "&:hover": { backgroundColor: "darkred" }, width: "80px", height: "35px" }}
              onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button
              sx={{ backgroundColor: "#2596BE", color: "white", "&:hover": { backgroundColor: "#1c7da0" }, width: "80px", height: "35px" }}
              onClick={handleOption}
              disabled={loader}>
              {actionLoader ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Confirm"}
            </Button>
          </DialogActions>
        </Box>
      </Modal>

      {/* Reason Modal */}
      <Modal
        open={reasonModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="reason-modal-title"
        aria-describedby="reason-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="reason-modal-title" variant="h6" component="h2">
            Reason
          </Typography>
          <Typography id="reason-modal-description" sx={{ mt: 2 }}>
            {selectedReason}
          </Typography>
        </Box>
      </Modal>

      {/* Rejection Info Modal */}
      <Modal
        open={rejectModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Reason for Rejection
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {selectedRejectInfo.rejectedreason}
          </Typography>
          <Typography sx={{ mt: 2, fontStyle: "italic" }}>
            Rejected by: {selectedRejectInfo.rejectedByName}
          </Typography>
        </Box>
      </Modal>

      {/* Tracker Modal */}
      <Modal
        open={trackerModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="tracker-modal-title"
        aria-describedby="tracker-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="tracker-modal-title" variant="h6" component="h2">
            Approval Tracker
          </Typography>
          {renderTracker()}
        </Box>
      </Modal>

      <PaginationTable
        totalItems={totalItems}
        itemsPerPage={quantity}
        onPageChange={handlePageClick}
        onItemsPerPageChange={handleItemsPerPageChange}
        currentPage={page}
      />
    </Paper>
  )
}