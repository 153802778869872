import React from "react"
import NewEmployeeTable from "../../Components/Tables/EmployeeTables/NewEmployeeTable"

function NewEmployee ( ) {
  return (
    <NewEmployeeTable/>
  )
}

export default NewEmployee
