import React, { useState, useEffect } from 'react'
import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import LastPageIcon from '@mui/icons-material/LastPage'
import FirstPageIcon from '@mui/icons-material/FirstPage'

const PaginationTable = ({ totalItems, itemsPerPage, onPageChange, onItemsPerPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(itemsPerPage)

  const totalPages = Math.ceil(totalItems / selectedItemsPerPage)

  const handlePageClick = (event, page) => {
    setCurrentPage(page)
    onPageChange(page)
  }

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = event.target.value
    setSelectedItemsPerPage(newItemsPerPage)
    setCurrentPage(1)
    onItemsPerPageChange(newItemsPerPage)
  }

  useEffect(() => {
  }, [currentPage, selectedItemsPerPage])

  return (
    <div className="row-pagination-container" style={{padding: "20px 0"}}>
      <div className="col-md-3">
        <div className="dataTables_info" id="basic-datatable_info" role="status" aria-live="polite">
          Showing{' '}
          {totalItems > 0 ? ((currentPage * selectedItemsPerPage - selectedItemsPerPage) + 1) : 0} to{' '}
          {Math.min(currentPage * selectedItemsPerPage, totalItems)} of {totalItems} entries
        </div>
      </div>

        <div className="col-md-7">
          <div className='fpage'>
            <button onClick={() => handlePageClick(null, 1)} disabled={currentPage === 1}>
              <FirstPageIcon />
            </button>
          </div>
          <div className="dataTables_paginate paging_simple_numbers" id="basic-datatable_paginate">
            <Pagination
              className='custom-pagination-item'
              count={totalPages}
              page={currentPage}
              onChange={handlePageClick}
              boundaryCount={2}
              siblingCount={2}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  className={`${currentPage === item.page ? 'selected' : ''}`}
                />
              )}
            />
          </div>
          <div className='lpage'>
            <button onClick={() => handlePageClick(null, totalPages)} disabled={currentPage >= totalPages}>
              <LastPageIcon />
            </button>
          </div>
        </div>

      <div className="col-md-2">
        <div>
          <span style={{paddingRight: '10px'}}>Rows Per Page:</span>
          <Select
            value={selectedItemsPerPage}
            onChange={handleItemsPerPageChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            style={{ minWidth: '10px', justifyContent: 'space-between' }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </div>
      </div>
    </div>
  )
}

export default PaginationTable
