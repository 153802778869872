import React, { useEffect, useState } from "react"
import {
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    Button,
    Modal,
    Typography,
    CircularProgress,
    TextField,
    Box,
    Stack,
    Chip
} from "@mui/material"
import {
    fetchLeaveApplications,
    changeLeaveStatus,
    changeEndDate
} from "../../../Actions/commonController"
import PaginationTable from "../../Pagination"
import { useAuth } from "../../../Context/authContext"
import { toast } from "react-toastify"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty"
import { FormatDate } from "../../Utilities/DateFormatter"
import SearchBar from "../../SearchBar"

const columns = [
    { id: "ID", label: "EMPID", minWidth: 100 },
    { id: "name", label: "NAME", minWidth: 100 },
    { id: "type", label: "LEAVE TYPE", minWidth: 100 },
    { id: "startDate", label: "START DATE", minWidth: 100 },
    { id: "endDate", label: "END DATE", minWidth: 100 },
    { id: "duration", label: "DURATION", minWidth: 100 },
    { id: "reason", label: "REASON", minWidth: 100 },
    { id: "status", label: "STATUS", minWidth: 100 },
    { id: "requestDate", label: "REQUEST DATE", minWidth: 100 },
    { id: "actions", label: "ACTIONS", minWidth: 200 },
]

export default function LeaveApplicationsTable() {
    const [page, setPage] = useState(1)
    const [quantity, setQuantity] = useState(10)
    const [totalItems, setTotalItems] = useState(0)
    const [data, setData] = useState([])
    const [reasonModalOpen, setReasonModalOpen] = useState(false)
    const [actionModalOpen, setActionModalOpen] = useState(false)
    const [trackerModalOpen, setTrackerModalOpen] = useState(false)
    const [selectedReason, setSelectedReason] = useState("")
    const [onConfirm, setOnConfirm] = useState("")
    const [loading, setLoading] = useState(false)
    const [actionType, setActionType] = useState("")
    const [approvedBy, setApprovedBy] = useState("")
    const [rejectReason, setRejectReason] = useState("")
    const [rejectModalOpen, setRejectModalOpen] = useState(false)
    const { eid } = useAuth()
    const [statusFilter, setStatusFilter] = useState("all")
    const [loader, setLoader] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [selectedLeave, setSelectedLeave] = useState(null)
    const [newEndDate, setNewEndDate] = useState("")
    const [searchTerm, setSearchTerm] = useState("")
    const [selectedRejectInfo, setSelectedRejectInfo] = useState({
        rejectedByName: "",
        rejectedreason: "",
    })
    const [trackerData, setTrackerData] = useState({
        pending: [],
        accepted: [],
        rejected: [],
    })

    const handleUpdateEndDate = async () => {
        try {
            const formattedNewEndDate = new Date(newEndDate).toISOString().split('T')[0]
            let APINewDate = {
                leaveid: selectedLeave.id,
                empId: selectedLeave.empid,
                startDate: selectedLeave.startdate,
                newEndDate: formattedNewEndDate,
                duration: selectedLeave.duration,
                type: selectedLeave.type,
            }
            setLoading(true)
            const { status } = await changeEndDate(APINewDate)
            if (status === "success") {
                toast.success("End date updated successfully")
                setEditModalOpen(false)
                fetchEmpLeaveData()
            } else {
                toast.error("Failed to update end date")
            }
        } catch (error) {
            toast.error("An error occurred while updating end date", error)
        }
        setLoading(false)
    }

    const fetchEmpLeaveData = async () => {
        try {
            let APIData = {
                empid: eid,
                status: statusFilter,
                search: searchTerm,
            }
            setLoader(true)
            let { data, status } = await fetchLeaveApplications(APIData)
            if (status === "success") {
                setData(data)
                setTotalItems(data.length)
            } else {
                console.error("Error fetching employee leave applications", status)
            }
        } catch (error) {
            console.error("Error in empLeave function", error)
        }
        setLoader(false)
    }

    const handleOption = async () => {
        try {
            setLoading(true)
            let APIData = {
                action: actionType.toLowerCase(),
                leaveid: onConfirm,
                empid: eid,
                reason: rejectReason,
            }
            const { status, message } = await changeLeaveStatus(APIData)
            if (status === "success") {
                setActionModalOpen(false)
                setRejectReason("")
                toast.success(message)
                fetchEmpLeaveData()
            }
        } catch (err) {
            toast.error(err.message)
        }
        setLoading(false)
        setReasonModalOpen(false)
        setOnConfirm("")
    }

    const handleSearch = async (term) => {
        setSearchTerm(term)
    }

    const handleFilter = (status) => {
        setStatusFilter(status)
        setPage(1)
    }

    const handlePageClick = (newPage) => {
        setPage(newPage)
    }

    const handleItemsPerPageChange = (newRowsPerPage) => {
        setQuantity(newRowsPerPage)
        setPage(1)
    }

    const handleButtonClick = (leaveid, action, empid, dayLength) => {
        setOnConfirm(leaveid)
        setActionType(action)
        setApprovedBy(empid)
        setActionModalOpen(true)
    }

    const handleRejectInfo = (rejectedByName, rejectedreason) => {
        setSelectedRejectInfo({ rejectedByName, rejectedreason })
        setRejectModalOpen(true)
    }

    const handleCloseModal = () => {
        setReasonModalOpen(false)
        setActionModalOpen(false)
        setTrackerModalOpen(false)
        setRejectModalOpen(false)
        setOnConfirm("")
    }

    const handleReasonButton = (item) => {
        setSelectedReason(item)
        setReasonModalOpen(true)
    }

    const handleTrackerButtonClick = (leave) => {
        const tracker = {
            pending: leave.pendingNames ? leave.pendingNames.split(",") : [],
            accepted: leave.acceptedNames ? leave.acceptedNames.split(",") : [],
            rejected: leave.rejectedNames ? leave.rejectedNames.split(",") : [],
        }
        setTrackerData(tracker)
        setTrackerModalOpen(true)
    }

    useEffect(() => {
        fetchEmpLeaveData()
    }, [eid, statusFilter, page, quantity, searchTerm])

    const renderTracker = () => {
        const steps = [
            ...trackerData.accepted.map((name) => ({ name, status: "approved" })),
            ...trackerData.pending.map((name) => ({ name, status: "pending" })),
            ...trackerData.rejected.map((name) => ({ name, status: "rejected" })),
        ]

        return (
            <Box display="flex" flexDirection="column" alignItems="center" padding="20px">
                {steps.map((step, index) => (
                    <Box key={index} display="flex" flexDirection="row" width="100%" alignItems="flex-start">
                        <Box position="relative" display="flex" flexDirection="column" alignItems="center" mr={2}>
                            <Box
                                width={20}
                                height={20}
                                borderRadius="50%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                bgcolor={
                                    step.status === "approved" ? "green" : step.status === "rejected" ? "red" : "gray"
                                }
                                color="white"
                                zIndex={1}
                            >
                                {step.status === "approved" ? (
                                    <CheckCircleIcon fontSize="small" />
                                ) : (
                                    <HourglassEmptyIcon fontSize="12px" />
                                )}
                            </Box>
                            {index < steps.length - 1 && (
                                <Box
                                    width={2}
                                    height={30}
                                    bgcolor={
                                        steps[index + 1].status === "approved"
                                            ? "green"
                                            : steps[index + 1].status === "rejected"
                                                ? "red"
                                                : "gray"
                                    }
                                    mt={1}
                                    mb={1}
                                />
                            )}
                        </Box>
                        <Typography>{step.name}</Typography>
                    </Box>
                ))}
            </Box>
        )
    }

    const chipColor = (status) => {
        switch (status.toLowerCase()) {
            case "pending":
                return "primary"
            case "cancelled":
                return "warning"
            case "rejected":
                return "error"
            case "accepted":
                return "success"
            default:
                return "default"
        }
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    }

    return (
        <>
            <Paper className="pagePaper">
                <span className="info">
                    <h5>Manage Leave Applications</h5>
                    <SearchBar searchText="Search by name..." onSearch={handleSearch} searchType="name" />
                </span>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "30px" }}>
                    <Button
                        variant="contained"
                        onClick={() => handleFilter("all")}
                        sx={{
                            backgroundColor: statusFilter === "all" ? "#2596BE" : "transparent",
                            color: statusFilter === "all" ? "white" : "#2596BE",
                            marginRight: "10px",
                            "&:hover": {
                                backgroundColor: "#2596BE",
                                color: "white",
                            },
                        }}
                    >
                        All
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleFilter("Pending")}
                        sx={{
                            backgroundColor: statusFilter === "Pending" ? "#2596BE" : "transparent",
                            color: statusFilter === "Pending" ? "white" : "#2596BE",
                            marginRight: "10px",
                            "&:hover": {
                                backgroundColor: "#2596BE",
                                color: "white",
                            },
                        }}
                    >
                        Pending
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleFilter("Accepted")}
                        sx={{
                            backgroundColor: statusFilter === "Accepted" ? "#2596BE" : "transparent",
                            color: statusFilter === "Accepted" ? "white" : "#2596BE",
                            marginRight: "10px",
                            "&:hover": {
                                backgroundColor: "#2596BE",
                                color: "white",
                            },
                        }}
                    >
                        Accepted
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleFilter("Rejected")}
                        sx={{
                            backgroundColor: statusFilter === "Rejected" ? "#2596BE" : "transparent",
                            color: statusFilter === "Rejected" ? "white" : "#2596BE",
                            marginRight: "10px",
                            "&:hover": {
                                backgroundColor: "#2596BE",
                                color: "white",
                            },
                        }}
                    >
                        Rejected
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleFilter("Cancelled")}
                        sx={{
                            backgroundColor: statusFilter === "Cancelled" ? "#2596BE" : "transparent",
                            color: statusFilter === "Cancelled" ? "white" : "#2596BE",
                            "&:hover": {
                                backgroundColor: "#2596BE",
                                color: "white",
                            },
                        }}
                    >
                        Cancelled
                    </Button>
                </div>

                <div className="row" style={{
                    paddingTop: "60px",
                }}>
                    <div className="col-md-12">
                        <TableContainer
                            sx={{
                                overflowY: "auto",
                                maxHeight: "500px"
                            }}
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                className="tableHeader"
                                                key={column.id}
                                                align="center"
                                                style={{
                                                    minWidth: column.minWidth,
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loader ? (
                                        <TableRow>
                                            <TableCell colSpan={11} style={{ padding: "30px 0", textAlign: "center" }}>
                                                <CircularProgress size={100} />
                                            </TableCell>
                                        </TableRow>
                                    ) : data && data.length > 0 ? (
                                        data.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center" className="p-0"> {item.empid}</TableCell>
                                                <TableCell align="center" className="p-0"> {item.name}</TableCell>
                                                <TableCell align="center" className="p-0"> {item.type}</TableCell>
                                                <TableCell align="center" className="p-0"> {FormatDate(item.startdate)}</TableCell>
                                                <TableCell align="center" className="p-0"> {FormatDate(item.enddate)}</TableCell>
                                                <TableCell align="center" className="p-0"> {item.duration}</TableCell>
                                                <TableCell align="center" className="p-0">
                                                    <Button variant="contained" className="tableButton" onClick={() => { handleReasonButton(item.reason) }}>
                                                        View
                                                    </Button>
                                                </TableCell>
                                                <TableCell>
                                                    <Stack direction="row" spacing={1}>
                                                        <Chip label={capitalizeFirstLetter(item.status)} color={chipColor(item.status)} />
                                                    </Stack>
                                                </TableCell>
                                                <TableCell>{FormatDate(item.createdAt)}</TableCell>
                                                <TableCell>
                                                    {item.status === "pending" ? (
                                                        <>
                                                            {item.pendingapproval && item.pendingapproval.split(',').includes(eid) ? (
                                                                <>
                                                                    <Button
                                                                        variant="contained"
                                                                        className="btn success"
                                                                        onClick={() => handleButtonClick(item.id, "Accepted")}
                                                                    >
                                                                        Accept
                                                                    </Button>
                                                                    <Button
                                                                        variant="contained"
                                                                        className="btn rejected"
                                                                        onClick={() => handleButtonClick(item.id, "Rejected")}
                                                                    >
                                                                        Reject
                                                                    </Button>
                                                                    <Button
                                                                        variant="contained"
                                                                        className="btn tracker"
                                                                        onClick={() => handleTrackerButtonClick(item)}
                                                                    >
                                                                        Tracker
                                                                    </Button>
                                                                </>
                                                            ) : item.acceptedapproval && item.acceptedapproval.split(',').includes(eid) ? (
                                                                <>
                                                                    <Button
                                                                        variant="contained"
                                                                        className="btn tracker"
                                                                        onClick={() => handleTrackerButtonClick(item)}
                                                                    >
                                                                        Tracker
                                                                    </Button>
                                                                    <Button
                                                                        variant="contained"
                                                                        className="btn warning"
                                                                        onClick={() => {
                                                                            setSelectedLeave(item);
                                                                            setNewEndDate(item.endDate);
                                                                            setEditModalOpen(true);
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </>
                                                    ) : item.status === "accepted" ? (
                                                        <>
                                                            <Button
                                                                variant="contained"
                                                                className="btn warning"
                                                                onClick={() => {
                                                                    setSelectedLeave(item);
                                                                    setNewEndDate(item.endDate);
                                                                    setEditModalOpen(true);
                                                                }}
                                                            >
                                                                Edit
                                                            </Button>
                                                        </>
                                                    ) : item.status === "rejected" ? (
                                                        <Button
                                                            variant="contained"
                                                            className="btn rjct"
                                                            onClick={() => handleRejectInfo(item.rejectedByName, item.rejectedreason)}
                                                        >
                                                            Info
                                                        </Button>
                                                    ) : item.status === "cancelled" ? (
                                                        "-"
                                                    ) : (
                                                        "-"
                                                    )}
                                                </TableCell>

                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={11} style={{ padding: "30px 0", textAlign: "center" }}>
                                                No data found
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <PaginationTable
                            totalItems={totalItems}
                            itemsPerPage={quantity}
                            onPageChange={handlePageClick}
                            onItemsPerPageChange={handleItemsPerPageChange}
                        />
                    </div>
                </div>
            </Paper>

            {/* Reason Modal */}
            <Modal
                open={reasonModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper className="floatingPaper">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Reason
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {selectedReason}
                    </Typography>
                </Paper>
            </Modal>

            {/* Tracker Modal */}
            <Modal
                open={trackerModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="tracker-modal-title"
                aria-describedby="tracker-modal-description"
            >
                <Paper className="floatingPaper">
                    <Typography id="tracker-modal-title" variant="h6" component="h2">
                        Approval Tracker
                    </Typography>
                    <Typography id="tracker-modal-description" sx={{ mt: 2 }}>
                        {renderTracker()}
                    </Typography>
                </Paper>
            </Modal>

            {/* Confirmation Modal */}
            <Modal
                open={actionModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="confirmation-modal-title"
                aria-describedby="confirmation-modal-description"
            >
                <Paper className="floatingPaper">
                    <Typography id="confirmation-modal-title" variant="h6" component="h2">
                        {actionType === "Accepted" ? "Accept Leave" : "Reject Leave"}
                    </Typography>
                    <Typography id="confirmation-modal-description" sx={{ my: 2, color: "gray" }}>
                        Are you sure you want to {actionType === "Accepted" ? "Accept" : "Reject"} this leave application?
                    </Typography>
                    {actionType === "Rejected" && (
                        <TextField
                            autoFocus
                            margin="dense"
                            id="reason"
                            label="Reason"
                            type="text"
                            multiline
                            rows={4}
                            fullWidth
                            value={rejectReason}
                            onChange={(e) => setRejectReason(e.target.value)}
                        />
                    )}
                    <div style={{ display: "flex", marginTop: "25px", width: "370px", justifyContent: "space-between" }}>
                        <Button
                            variant="contained"
                            onClick={handleCloseModal}
                            style={{ backgroundColor: "#FF0000", color: "white", width: "60px", height: "35px" }}
                        >
                            No
                        </Button>
                        <Button
                            sx={{
                                backgroundColor: loading ? "primary.main" : "primary.main",
                                color: "white",
                                "&:hover": {
                                    backgroundColor: loading ? "primary.dark" : "primary.dark",
                                },
                                width: "60px",
                                height: "35px",
                            }}
                            onClick={handleOption}
                            autoFocus
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={20} sx={{ color: "white" }} /> : "Yes"}
                        </Button>
                    </div>
                </Paper>
            </Modal>

            {/* Rejection Info Modal */}
            <Modal
                open={rejectModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        border: "none",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2">
                        Reason for Rejection
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        {selectedRejectInfo.rejectedreason}
                    </Typography>
                    <Typography sx={{ mt: 2, fontStyle: "italic" }}>
                        Rejected by: {selectedRejectInfo.rejectedByName}
                    </Typography>
                </Box>
            </Modal>

            {/* Edit End Date Modal */}
            <Modal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                aria-labelledby="edit-modal-title"
                aria-describedby="edit-modal-description"
            >
                <Paper className="floatingPaper">
                    <Typography id="edit-modal-title" variant="h6" component="h2">
                        Edit
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="newEndDate"
                        label="New End Date"
                        type="date"
                        fullWidth
                        value={newEndDate}
                        onChange={(e) => setNewEndDate(e.target.value)}
                        InputProps={{
                            inputProps: {
                                min: selectedLeave ? selectedLeave.startdate : ""
                            }
                        }}
                    />
                    <div style={{ display: "flex", marginTop: "25px", justifyContent: "space-between", fontSize: "12px" }}>
                        <Button
                            variant="contained"
                            onClick={() => setEditModalOpen(false)}
                            style={{ backgroundColor: "#FF0000", color: "white", width: "60px", height: "35px" }}
                        >
                            Cancel
                        </Button>
                        <Button
                            sx={{ backgroundColor: "#2596BE", color: "white", "&:hover": { backgroundColor: "#1c7da0" }, width: "80px", height: "35px" }}
                            onClick={handleUpdateEndDate}
                            disabled={loading}>
                            {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Save"}
                        </Button>
                    </div>
                </Paper>
            </Modal>
        </>
    )
}