import React from 'react'
import TrackHistoryTable from '../../Components/Tables/EmployeeTables/TrackHistoryTable'

function TrackHistory() {
    return (
        <div>
            <h1>Employee Track History</h1>
            <TrackHistoryTable />
        </div>
    )
}

export default TrackHistory
