import React from "react"
import DashboardAdmin from "../../Components/dashboardAdmin"
import { useAuth } from "../../Context/authContext"
import DashboardEmployees from "../../Components/dashboardEmployees"

function Dashboard() {
  const { tier } = useAuth()
  
  return (
    <>
      <h1>Dashboard</h1>
      { tier === 3 ? <DashboardEmployees /> : <DashboardAdmin /> }
    </>
  )
}

export default Dashboard
