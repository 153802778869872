import React, { useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import { CircularProgress } from "@mui/material"
import { activeEmp, rejectEmp } from "../../Actions/commonController"
import { toast } from "react-toastify"

export default function ConfirmationModal({ open, onClose, message, empId, email, type }) {
  const [loading, setLoading] = useState(false)

  const handleYesClick = async () => {
    setLoading(true)
    if (type === "accept") {
      await hireEmployee()
    } else if (type === "reject") {
      await rejectEmployee()
    }
    setLoading(false)
  }

  const hireEmployee = async () => {
    try {
      let APIData = {
        empid: empId,
        email: email,
      }
      let { status } = await activeEmp(APIData)
      if (status === "success") {
        toast.success("Employee successfully activated!")
        onClose("success")
      } else {
        toast.error("Error moving employee! (Contact administrator)")
      }
    } catch (err) {
      toast.error("Error in server! (Contact administrator)")
    }
  }

  const rejectEmployee = async () => {
    try {
      let APIData = {
        empid: empId
      }
      let { status } = await rejectEmp(APIData)
      if (status === "success") {
        toast.success("Employee rejected!")
        onClose("success")
      } else {
        toast.error("Error moving employee! (Contact administrator)")
      }
    } catch {
      toast.error("Error in server! (Contact administrator)")
    }
  }

  return (
    <Dialog open={open} onClose={() => { onClose("") }} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">{message.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message.body}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between", padding: "20px" }}>
        <Button
          style={{
            backgroundColor: "#FF0000",
            color: "white",
            width: "60px",
            height: "35px",
          }}
          autoFocus
          onClick={() => { onClose("") }}
          disabled={loading}
        >
          No
        </Button>
        <Button
          sx={{
            backgroundColor: 'primary.main',
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
            width: '60px',
            height: '35px',
          }}
          onClick={handleYesClick}
          autoFocus
          disabled={loading}
        >
          {loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : "Yes"}

        </Button>

      </DialogActions>
    </Dialog>
  )
}