export const Validate = (formValue) => {
    let errors = {}

    if (formValue.name === "") errors.name = "Name is required"
    else if (!/^[A-Za-z\s]*$/.test(formValue.name)) errors.name = "Name must contain only alphabets"

    if (formValue.email === "") errors.email = "Email is required"
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValue.email)) errors.email = "Email is invalid"

    const numberRegex = /^[0-9+()\-\s]*$/

    if (formValue.contactuae === "") errors.contactuae = "Contact No. is required"
    else if (!numberRegex.test(formValue.contactuae)) errors.contactuae = "Contact No. is invalid"

    if (formValue.contacthome && !numberRegex.test(formValue.contacthome)) errors.contacthome = "Home Contact No. is invalid"
    if (formValue.emergencycontactuaeno && !numberRegex.test(formValue.emergencycontactuaeno)) errors.emergencycontactuaeno = "Emergency Contact No. (UAE) is invalid"
    if (formValue.emergencycontacthomeno && !numberRegex.test(formValue.emergencycontacthomeno)) errors.emergencycontacthomeno = "Emergency Contact No. (Home Country) is invalid"
    if (formValue.companysimnumber && !numberRegex.test(formValue.companysimnumber)) errors.companysimnumber = "Company SIM No. is invalid"

    if (formValue.dob === "") errors.dob = "Date of Birth is required"
    if (formValue.age === "") errors.age = "Age is required"
    if (formValue.nationality === "") errors.nationality = "Nationality is required"
    if (formValue.gender === "") errors.gender = "Gender is required"
    if (formValue.maritalstatus === "") errors.maritalstatus = "Marital Status is required"

    if (formValue.basicsalary && !numberRegex.test(formValue.basicsalary)) errors.basicsalary = "Basic Salary is invalid"
    if (formValue.housingallowance && !numberRegex.test(formValue.housingallowance)) errors.housingallowance = "Housing Allowance is invalid"
    if (formValue.transportallowance && !numberRegex.test(formValue.transportallowance)) errors.transportallowance = "Tranport Allowance is invalid"
    if (formValue.foodallowance && !numberRegex.test(formValue.foodallowance)) errors.foodallowance = "Food Allowance is invalid"
    if (formValue.mobileallowance && !numberRegex.test(formValue.mobileallowance)) errors.mobileallowance = "Mobile Allowance is invalid"
    if (formValue.otherallowance && !numberRegex.test(formValue.otherallowance)) errors.otherallowance = "Other Allowance is invalid"
    if (formValue.contractsalary && !numberRegex.test(formValue.contractsalary)) errors.contractsalary = "Contract Salary is invalid"

    return errors
}