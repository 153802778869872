import React, { useState, useEffect } from "react"
import { fetchSingleEmpRecord, fetchTrackHistory } from "../../../Actions/commonController"
import { useParams, Link } from "react-router-dom"
import {
    Paper,
    Box,
    Grid,
    Typography,
    IconButton,
    CircularProgress
} from "@mui/material"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { useAuth } from "../../../Context/authContext"
import { FormatDate } from "../../Utilities/DateFormatter"

const initialValue = {
    name: "",
    email: "",
    gender: "",
    nationality: "",
    dob: "",
    age: "",
    passportno: "",
    passportexpiry: "",
    contactuae: "",
    maritalstatus: "",
    religion: "",
    photoloc: [],
    eidno: "",
    gpssa: "",
    insuredno: "",
    insureddate: "",
    dateofjoin: "",
    designation: "",
    officeloc: "",
    bankname: "",
    dept: "",
    division: "",
    hiredfrom: "",
    sourcecode: "",
    linemanager: "",
    teamleader: "",
    visaid: "",
    dateofjoinmol: "",
    workpermitnumber: "",
    workpermitstartdate: "",
    workpermitexpiry: "",
    personalcodemohre: "",
    contracttype: "",
    sponsorname: "",
    labourcard: "",
    visafileno: "",
    visadesignation: "",
    visatype: "",
    visaexpiry: "",
    visacancellation: "",
    contacthome: "",
    emergencycontactuaename: "",
    emergencycontactuaeno: "",
    emergencycontacthomename: "",
    emergencycontacthomeno: "",
    companysimnumber: "",
    simissuedate: "",
    simlastdate: "",
    simplan: "",
    medicalprovider: "",
    medicalexpiry: "",
    medicalcardno: "",
    basicsalary: "",
    housingallowance: "",
    transportallowance: "",
    foodallowance: "",
    mobileallowance: "",
    otherallowance: "",
    totalsalary: "",
    contractsalary: "",
    accnumber: "",
    iban: "",
    salarybankname: "",
    folderid: "",
    remarks: "",
    // contractupdate: [],
    docs: []
}

const TrackHistoryForm = () => {
    const { empid } = useParams()
    const [currentRecord, setCurrentRecord] = useState(initialValue)
    const [uploadedImage, setUploadedImage] = useState("")
    const [historyRecord, setHistoryRecord] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0)
    const [author, setAuthor] = useState("")
    const [changedAt, setChangedAt] = useState("")
    const [loading, setLoading] = useState(true)
    const { eid } = useAuth()

    const fetchRecord = async () => {
        try {
            let APIData = {
                empid: empid,
            }

            const { data, status, docs } = await fetchSingleEmpRecord(APIData)
            if (status === "success") {

                const age = calculateAge(data.dob)

                const formattedData = {
                    ...data,
                    dob: data.dob ? FormatDate(data.dob) : "",
                    passportexpiry: data.passportexpiry ? FormatDate(data.passportexpiry) : "",
                    dateofjoin: data.dateofjoin ? FormatDate(data.dateofjoin) : "",
                    insureddate: data.insureddate ? FormatDate(data.insureddate) : "",
                    dateofjoinmol: data.dateofjoinmol ? FormatDate(data.dateofjoinmol) : "",
                    workpermitstartdate: data.workpermitstartdate ? FormatDate(data.workpermitstartdate) : "",
                    workpermitexpiry: data.workpermitexpiry ? FormatDate(data.workpermitexpiry) : "",
                    visaexpiry: data.visaexpiry ? FormatDate(data.visaexpiry) : "",
                    medicalexpiry: data.medicalexpiry ? FormatDate(data.medicalexpiry) : "",
                    visacancellation: data.visacancellation ? FormatDate(data.visacancellation) : "",
                    simissuedate: data.simissuedate ? FormatDate(data.simissuedate) : "",
                    simlastdate: data.simlastdate ? FormatDate(data.simlastdate) : ""
                }
                setCurrentRecord({ ...formattedData, docs })
                setUploadedImage(data.photoloc)

            }
        } catch (error) {
            console.error("Error fetching employee data:", error)
        }
    }


    function calculateAge(dob) {
        const today = new Date()
        const birthDate = new Date(dob)
        let age = today.getFullYear() - birthDate.getFullYear()
        const m = today.getMonth() - birthDate.getMonth()
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--
        }
        return age
    }

    const historyData = async () => {
        try {
            let APIData = {
                empid: empid,
                author: eid
            }

            const { data, status } = await fetchTrackHistory(APIData)
            if (!Array.isArray(data) || data.length === 0) {
                throw new Error("No history data available")
            }

            const parsedLogs = data.map(record => {
                const logs = JSON.parse(record.logs)
                const parsedLogs = []
                logs.forEach(log => {
                    Object.entries(log).forEach(([field, values]) => {
                        parsedLogs.push({ field, before: values.before, after: values.after })
                    })
                })
                return {
                    ...record,
                    author: status === "success" ? record.name : "Unknown",
                    logs: parsedLogs
                }
            })

            if (parsedLogs.length > 0) {
                setHistoryRecord(parsedLogs)
                setCurrentIndex(parsedLogs.length - 1)

                const latestRecord = parsedLogs[parsedLogs.length - 1]
                setChangedAt(FormatDate(latestRecord.changedAt))
                setAuthor(latestRecord.author)
            }
        } catch (error) {
            console.error("Error fetching history data:", error)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            await fetchRecord()
            await historyData()
            setLoading(false)
        }
        fetchData()
    }, [empid, eid])

    const goToPreviousChange = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1)
            updateAuthorAndChangedAt(currentIndex - 1)
        }
    }

    const goToNextChange = () => {
        if (currentIndex < historyRecord.length - 1) {
            setCurrentIndex(currentIndex + 1)
            updateAuthorAndChangedAt(currentIndex + 1)
        }
    }

    const updateAuthorAndChangedAt = (index) => {
        const record = historyRecord[index]
        if (record) {
            setAuthor(record.author)
            setChangedAt(FormatDate(record.changedAt))
        }
    }

    const renderField = (field, label) => {
        let currentValue = currentRecord[field] || "-"
        let historyValue = historyRecord[currentIndex] && historyRecord[currentIndex].logs
            ? historyRecord[currentIndex].logs.find(log => log.field === field) || {}
            : {}
        let previousValue = historyValue.before

        let currentColor = previousValue ? (currentValue !== previousValue ? "green" : "black") : "black"
        let previousColor = previousValue ? "red" : "black"

        return (
            <Grid item xs={12} sm={6}>
                <Grid container direction="row">
                    <Grid item xs={6}>
                        <Typography fontSize={12} color={"black"} gutterBottom>{label}</Typography>
                        <Typography fontSize={16} color={"black"} style={{ color: currentColor }}>{currentValue}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography fontSize={12} color={"black"} gutterBottom>(Previous)</Typography>
                        <Typography fontSize={16} color={"black"} style={{ color: previousColor }}>{previousValue}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <Box>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress size={50} />
                </Box>
            ) : (
                <>
                    {/* Navigation Buttons */}
                    <Box marginBottom={"30px"}>
                        <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginBottom: 2 }}>
                            <Grid item>
                                <IconButton
                                    onClick={goToPreviousChange}
                                    disabled={currentIndex === 0}
                                    sx={{
                                        width: 35,
                                        height: 35,
                                        backgroundColor: "#2596BE",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor: "#1b78a0"
                                        },
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        paddingRight: "3px",
                                    }}
                                >
                                    <ArrowBackIosIcon sx={{ fontSize: "20px" }} />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={goToNextChange}
                                    disabled={historyRecord.length === 0 || (currentIndex === historyRecord.length - 1)}
                                    sx={{
                                        width: 35,
                                        height: 35,
                                        backgroundColor: "#2596BE",
                                        color: "white",
                                        "& .MuiSvgIcon-root": {
                                            fontSize: "20px",
                                            margin: "auto"
                                        },
                                        "&:hover": {
                                            backgroundColor: "#1b78a0"
                                        }
                                    }}
                                >
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Created by and Author and ChangedAt */}
                    <Box marginBottom={"30px"}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper className="itemPaper">
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Typography fontSize={12} color={"black"} gutterBottom>User Created By:</Typography>
                                            <Typography>{currentRecord.createdBy || "System Administrator"}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography fontSize={12} color={"black"} gutterBottom>Change Updated By:</Typography>
                                            <Typography>{author}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography fontSize={12} color={"black"} gutterBottom>Date of Change:</Typography>
                                            <Typography>{changedAt}</Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Personal Information Section */}
                    <Box marginBottom={"30px"}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper className="itemPaper">
                                    <h3 className="cardTitle">PERSONAL INFORMATION</h3>
                                    <Grid container spacing={2} alignItems="center">
                                        {renderField("empid", "Emp ID")}
                                        {renderField("name", "Full Name")}
                                        {renderField("email", "E-mail")}
                                        {renderField("contactuae", "Contact No.")}
                                        {renderField("dob", "Date of Birth")}
                                        {renderField("age", "Age")}
                                        {renderField("nationality", "Nationality")}
                                        {renderField("gender", "Gender")}
                                        {renderField("maritalstatus", "Marital Status")}
                                        {renderField("religion", "Religion")}
                                        {renderField("remarks", "Remarks")}
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Passport & Visa Information Section */}
                    <Box marginBottom={"30px"}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper className="itemPaper">
                                    <h3 className="cardTitle">PASSPORT & VISA INFORMATION</h3>
                                    <Grid container spacing={2} alignItems="center">
                                        {renderField("passportno", "Passport No.")}
                                        {renderField("passportstatus", "Passport Status")}
                                        {renderField("passportexpiry", "Passport Expiry")}
                                        {renderField("sponsorname", "Sponsor Name")}
                                        {renderField("labourcard", "Labour Card")}
                                        {renderField("contracttype", "Contract Type")}
                                        {renderField("visafileno", "Visa File No.")}
                                        {renderField("visaid", "UID")}
                                        {renderField("visadesignation", "Visa Designation")}
                                        {renderField("visatype", "Visa Status")}
                                        {renderField("visaexpiry", "Visa Expiry")}
                                        {renderField("visacancellation", "Visa Cancellation Date")}
                                        {renderField("eidno", "Emirates ID No.")}
                                        {renderField("workpermitnumber", "Work Permit No.")}
                                        {renderField("workpermitstartdate", "Work Permit Start Date")}
                                        {renderField("workpermitexpiry", "Work Permit Expiry")}
                                        {renderField("personalcodemohre", "Personal Code (MOHRE)")}
                                        {renderField("medicalprovider", "Medical Insurance Provider")}
                                        {renderField("medicalcardno", "Medical Insurance Card No.")}
                                        {renderField("medicalexpiry", "Medical Insurance Expiry")}
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Employment Information Section */}
                    <Box marginBottom={"30px"}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper className="itemPaper">
                                    <h3 className="cardTitle">EMPLOYMENT DETAILS</h3>
                                    <Grid container spacing={2} alignItems="center">
                                        {renderField("dateofjoin", "Date of Join (Actual)")}
                                        {renderField("dateofjoinmol", "MOL Start Date ")}
                                        {renderField("hiredfrom", "Hired From")}
                                        {renderField("officeloc", "Office Location")}
                                        {renderField("designation", "Designation")}
                                        {renderField("dept", "Department")}
                                        {renderField("division", "Division")}
                                        {renderField("linemanager", "Line Manager")}
                                        {renderField("teamleader", "Team Leader")}
                                        {renderField("sourcecode", "Source Code")}
                                        {renderField("bankname", "Bank Name")}
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Contact Information Section */}
                    <Box marginBottom={"30px"}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper className="itemPaper">
                                    <h3 className="cardTitle">CONTACT DETAILS</h3>
                                    <Grid container spacing={2} alignItems="center">
                                        {renderField("contacthome", "Contact No. (Home Country)")}
                                        {renderField("emergencycontactuaename", "Emergency Contact Name (UAE)")}
                                        {renderField("emergencycontactuaeno", "Emergency Contact No. (UAE)")}
                                        {renderField("emergencycontacthomename", "Emergency Contact Name (Home Country)")}
                                        {renderField("emergencycontacthomeno", "Emergency Contact No. (Home Country)")}
                                        {renderField("companysimnumber", "Company SIM No.")}
                                        {renderField("simissuedate", "SIM Issue Date")}
                                        {renderField("simlastdate", "SIM Last Date")}
                                        {renderField("simplan", "SIM Plan")}

                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Salary Information Section */}
                    <Box marginBottom={"30px"}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper className="itemPaper">
                                    <h3 className="cardTitle">SALARY DETAILS</h3>
                                    <Grid container spacing={2} alignItems="center">
                                        {renderField("basicsalary", "Basic Salary")}
                                        {renderField("housingallowance", "Housing Allowance")}
                                        {renderField("transportallowance", "Transport Allowance")}
                                        {renderField("foodallowance", "Food Allowance")}
                                        {renderField("mobileallowance", "Mobile Allowance")}
                                        {renderField("otherallowance", "Other Allowance")}
                                        {renderField("totalsalary", "Total Salary")}
                                        {renderField("contractsalary", "Contract Salary")}
                                        {renderField("accnumber", "Account No.")}
                                        {renderField("iban", "IBAN")}
                                        {renderField("salarybankname", "Bank No.")}
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>

                </>
            )}
        </Box>
    )
}

export default TrackHistoryForm
