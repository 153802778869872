import React from "react"
import ExistingEmployeeTable from "../../Components/Tables/EmployeeTables/ExistingEmployeeTable"

function ExistingEmployee ( ) {
  return (
    <ExistingEmployeeTable/>
  )
}

export default ExistingEmployee
