import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import CloseIcon from "@mui/icons-material/Close"
import { Modal, CircularProgress, Divider } from "@mui/material"
import { fetchEmpJoiningDetails } from "../../Actions/commonController"
import moment from "moment"

export default function JoiningDetailsModal({ open, onClose, empId }) {
  const [loader, setLoader] = useState(false)
  const [joiningDetails, setJoiningDetails] = useState(null)

  const fetchData = async () => {
    try {
      let APIData = {
        empid: empId,
      }
      if (empId) {
        setLoader(true)
        let { data, status } = await fetchEmpJoiningDetails(APIData)
        if (status === "success") setJoiningDetails(data[0])
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
      console.error("Error fetching personal details data:", error)
    }
  }

  useEffect(() => {
    if (open) fetchData()
  }, [empId, open])

  const FormatDate = (date) => {
    return moment(date).format("Do MMMM, YYYY")
  }

  return (
    <Modal
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      open={open}
      onClose={onClose}
      className="modal"
      keepMounted
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 id="keep-mounted-modal-title" className="name">
            Joining Details
          </h2>
          <button
            type="button"
            onClick={onClose}
            className="close-icon"
          >
            <CloseIcon />
          </button>
        </div>
        <Divider className="divider" />
        <div className="modalBody">
          <p id="keep-mounted-modal-description" className="field">
            Date of Join (Actual)
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              joiningDetails?.dateofjoin ? FormatDate(joiningDetails.dateofjoin) : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            MOL Start Date
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              joiningDetails?.dateofjoinmol ? FormatDate(joiningDetails.dateofjoinmol) : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Office Location
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              joiningDetails?.officeloc ? joiningDetails.officeloc : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Designation
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              joiningDetails?.designation ? joiningDetails.designation : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Department/Division
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              joiningDetails?.dept ? joiningDetails.dept : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Line Manager
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              joiningDetails?.linemanager ? joiningDetails.linemanager : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Team Leader
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              joiningDetails?.teamleader ? joiningDetails.teamleader : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Source Code
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              joiningDetails?.sourcecode ? joiningDetails.sourcecode : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Bank Name
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              joiningDetails?.bankname ? joiningDetails.bankname : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Medical Insurance Provider
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              joiningDetails?.medicalprovider ? joiningDetails.medicalprovider : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Medical Insurance Card Number
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              joiningDetails?.medicalcardno ? joiningDetails.medicalcardno : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Medical Insurance Expiry
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              joiningDetails?.medicalexpiry ? FormatDate(joiningDetails.medicalexpiry) : "-"
            )}
          </h4>
        </div>
      </div>
    </Modal>
  )
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props
  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  )
})

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
}