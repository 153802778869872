import React, { useEffect } from "react"
import Sidebar from "../Components/sideBar"
import Header from "../Components/Header"

const Layout = ({ component }) => {
    useEffect(() => {
    }, [])
    return (
        <div className='dashboard'>
            <div className="row1">
                <div className="col1">
                    <Sidebar />
                </div>
                <div className="col2">
                    <div className="dataRow">
                        <Header/>
                    </div>
                    <div className="row2">
                        {component}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layout