export const AdjustFilename = (filename, existingFiles) => {
    const fileBaseName = filename.substring(0, filename.lastIndexOf("."))
    const fileExtension = filename.substring(filename.lastIndexOf("."))
    let newFilename = filename

    const existingNames = existingFiles.map(file => file.name)

    if (existingNames.includes(newFilename)) {
      let counter = 1
      newFilename = `${fileBaseName} (${counter})${fileExtension}`
      while (existingNames.includes(newFilename)) {
        counter++
        newFilename = `${fileBaseName} (${counter})${fileExtension}`
      }
    }

    return newFilename
  }