import React, { useEffect, useState } from "react"
import SearchBar from "./SearchBar"
import { checkEmpExist } from "../Actions/commonController"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useAuth } from "../Context/authContext"

const Header = () => {
  const navigation = useNavigate()
  const { tier, eid } = useAuth()

  const handleSearch = async (searchData, type) => {
    try {
      let APIData = {
        fieldData: searchData,
        fieldType: type
      }
      let { status, data, empid } = await checkEmpExist(APIData)
      if (status === "success") {
        if (data === 0) {
          toast.error("Employee does not exist!")
        } else {
          console.log("Navigating to the employee edit with empid:", empid)
          navigation(`/employee/edit-employee/${empid}`)
          toast.success("Employee exists!") 
        }
      }
    } catch (error) {
      console.error("Error checking employee existence:", error)
    }
  }

  return (
    <div className="searchHeader">
      {
        tier != 3 &&
        <>
          <SearchBar onSearch={handleSearch} searchText={"Search by name..."} searchType={"name"} />
          <SearchBar onSearch={handleSearch} searchText={"Search by empid..."} searchType={"empid"} />
        </>
      }
    </div>
  )
}

export default Header
