import React from 'react'
import Sidebar from '../../Components/sideBar'

function InterviewScheduled() {
  return (
    <h1>
      Interview Scheduled
    </h1>
  )
}

export default InterviewScheduled
