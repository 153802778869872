import { React, useState, useEffect } from "react"
import {
  Grid,
  Paper,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  styled,
  CircularProgress,
  Typography,
} from "@mui/material"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import {
  applySalaryCert,
  fetchExpiryDates,
  fetchTotalEmp,
  fetchNewMonthlyHires,
} from "../Actions/commonController"
import WarningIcon from "@mui/icons-material/Warning"
import { useAuth } from "../Context/authContext"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const formatDate = (dateString) => {
  const date = new Date(dateString)
  const day = date.getDate()
  const month = date.toLocaleString("default", { month: "long" })
  const year = date.getFullYear()

  const suffix = (d) => {
    if (d > 3 && d < 21) return "th"
    switch (d % 10) {
      case 1: return "st"
      case 2: return "nd"
      case 3: return "rd"
      default: return "th"
    }
  }

  return `${day}${suffix(day)} ${month}, ${year}`
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: "5px",
  fontFamily: "Open Sans",
  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
}))

const RowContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing("20px"),
}))

const Divider = styled("hr")({
  height: "4px",
  borderTop: "1px solid #ccc",
  background: "linear-gradient(to right, #00cc00, #00ff00)",
})

export default function DashoardAdmin() {

  const [salaryCertOpen, setSalaryCertOpen] = useState(false)
  const [reason, setReason] = useState("")
  const [expiryLoader, setExpiryLoader] = useState(false)
  const [loading, setLoading] = useState(false)
  const [filteredExpiryDates, setFilteredExpiryDates] = useState([])
  const [expiryDates, setExpiryDates] = useState([])
  const [expiryType, setExpiryType] = useState("all")
  const [counts, setCounts] = useState({
    passportExpiryCount: 0,
    workPermitExpiryCount: 0,
    visaExpiryCount: 0,
    medicalExpiryCount: 0,
  })
  const [activeEmpCount, setActiveEmpCount] = useState(0)
  const [newEmpCount, setNewEmpCount] = useState(0)
  const { eid } = useAuth()
  const navigate = useNavigate()

  const handleClickOpen = () => {
    setReason("")
    setSalaryCertOpen(true)
  }

  const handleClose = () => {
    setReason("")
    setSalaryCertOpen(false)
  }

  const handleLeaveFormOpen = () => {
    navigate("/leave/leave-request-form")
  }

  const handleConfirm = async () => {
    try {
      let APIData = {
        empid: eid,
        reason: reason,
      }
      setLoading(true)
      const { status } = await applySalaryCert(APIData)
      if (status === "success") {
        toast.success("Salary certificate request sent successfully!")
      } else {
        toast.error("Request is already placed!")
      }
    } catch (error) {
      toast.error("Error requesting salary certificate! (Contact administrator")
    }
    setLoading(false)
    handleClose()
  }

  const addMonths = (date, months) => {
    let d = new Date(date)
    d.setMonth(d.getMonth() + months)
    return d
  }

  const isDateWithinNextTwoMonths = (date) => {
    const today = new Date()
    const twoMonthsFromToday = addMonths(today, 2)
    return date <= twoMonthsFromToday && date >= today
  }

  const calculateDaysLeft = (expiryDate) => {
    const today = new Date()
    const timeDiff = new Date(expiryDate) - today
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
  }

  const generateExpiryMessages = (employee) => {
    const { name, passportexpiry, workpermitexpiry, visaexpiry, medicalexpiry } = employee
    const messages = []

    if (expiryType === "all" || expiryType === "passportexpiry") {
      if (passportexpiry && isDateWithinNextTwoMonths(new Date(passportexpiry))) {
        const daysLeft = calculateDaysLeft(passportexpiry)
        messages.push({
          name: `${name} (${daysLeft} days left)`,
          message: `${name}'s passport is going to expire on <b>${formatDate(passportexpiry)}</b>`,
          date: new Date(passportexpiry),
        })
      }
    }

    if (expiryType === "all" || expiryType === "workpermitexpiry") {
      if (workpermitexpiry && isDateWithinNextTwoMonths(new Date(workpermitexpiry))) {
        const daysLeft = calculateDaysLeft(workpermitexpiry)
        messages.push({
          name: `${name} (${daysLeft} days left)`,
          message: `${name}'s work permit is going to expire on <b>${formatDate(workpermitexpiry)}</b>`,
          date: new Date(workpermitexpiry),
        })
      }
    }

    if (expiryType === "all" || expiryType === "visaexpiry") {
      if (visaexpiry && isDateWithinNextTwoMonths(new Date(visaexpiry))) {
        const daysLeft = calculateDaysLeft(visaexpiry)
        messages.push({
          name: `${name} (${daysLeft} days left)`,
          message: `${name}'s visa is going to expire on <b>${formatDate(visaexpiry)}</b>`,
          date: new Date(visaexpiry),
        })
      }
    }

    if (expiryType === "all" || expiryType === "medicalexpiry") {
      if (medicalexpiry && isDateWithinNextTwoMonths(new Date(medicalexpiry))) {
        const daysLeft = calculateDaysLeft(medicalexpiry)
        messages.push({
          name: `${name} (${daysLeft} days left)`,
          message: `${name}'s medical is going to expire on <b>${formatDate(medicalexpiry)}</b>`,
          date: new Date(medicalexpiry),
        })
      }
    }

    return messages
  }

  const fetchAndFilterExpiryDates = async () => {
    try {
      setExpiryLoader(true)
      let APIData = {
        empid: eid,
        expiryType,
      }
      const { status, data, counts } = await fetchExpiryDates(APIData)
      if (status === "success") {
        const allMessages = data.flatMap(employee => generateExpiryMessages(employee))
        allMessages.sort((a, b) => a.date - b.date)
        setExpiryDates(allMessages)

        setCounts({
          passportExpiryCount: counts?.passportExpiryCount || 0,
          workPermitExpiryCount: counts?.workPermitExpiryCount || 0,
          visaExpiryCount: counts?.visaExpiryCount || 0,
          medicalExpiryCount: counts?.medicalExpiryCount || 0,
        })

      } else {
        toast.error("Failed to fetch employee expiry dates! (Contact Administrator)")
      }
    } catch (err) {
      console.error("Error fetching expiry dates:", err)
    }
    setExpiryLoader(false)
  }

  const applyFilter = (expiryDates, filterType) => {
    if (filterType === "all") {
      setFilteredExpiryDates(expiryDates)
    } else {
      setFilteredExpiryDates(expiryDates.filter((e) => e.type === filterType))
    }
  }

  const fetchActiveEmployeeCounts = async () => {
    try {
      setLoading(true)

      const { status, data } = await fetchTotalEmp()
      if (status === "success") {
        setActiveEmpCount(data)
      } else {
        console.error("Failed to fetch active employee count")
      }
    } catch (error) {
      console.error("Error fetching employee counts:", error)
    } finally {
      setLoading(false)
    }
  }

  const fetchNewMonthlyEmployeeCounts = async () => {
    try {
      setLoading(true)

      const { status, data } = await fetchNewMonthlyHires()
      if (status === "success") {
        setNewEmpCount(data)
      } else {
        console.error("Failed to fetch new employee count")
      }
    } catch (error) {
      console.error("Error fetching employee counts:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchNewMonthlyEmployeeCounts()
    fetchActiveEmployeeCounts()
  }, [])

  useEffect(() => {
    fetchAndFilterExpiryDates()
  }, [expiryType])

  const allCount = counts.passportExpiryCount + counts.workPermitExpiryCount + counts.visaExpiryCount + counts.medicalExpiryCount

  return (
    <Box>
      {/* row 1 */}
      <RowContainer>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Item sx={{ padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">TOTAL NO. OF EMPLOYEES</h2>
                <h4>{loading ? <CircularProgress size={24} /> : activeEmpCount}</h4>
                <div className="dividerParent">
                  <Divider />
                </div>
                {/* <div className="percentage">
                  <div className="box">
                    <ArrowDropUpIcon /> 4.6%
                  </div>
                  <p>Today</p>
                </div> */}
              </div>
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item sx={{ padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">MONTHLY NEW HIRES</h2>
                <h4>{loading ? <CircularProgress size={24} /> : newEmpCount}</h4>
                <div className="dividerParent">
                  <Divider />
                </div>
                {/* <div className="percentage">
                  <div className="box">
                    <ArrowDropDownIcon /> 10%
                  </div>
                  <p>Month</p>
                </div> */}
              </div>
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item sx={{ padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">DEPARTMENTS</h2>
                <h4>-</h4>
                <div className="dividerParent">
                  <Divider />
                </div>
                {/* <div className="percentage">
                  <div className="box">
                    <ArrowDropUpIcon /> 2.3%
                  </div>
                  <p>Today</p>
                </div> */}
              </div>
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item sx={{ padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">NO. OF OPEN POSITIONS</h2>
                <h4>-</h4>
                <div className="dividerParent">
                  <Divider />
                </div>
                {/* <div className="percentage">
                  <div className="box">
                    <ArrowDropDownIcon /> 2.3%
                  </div>
                  <p>Today</p>
                </div> */}
              </div>
            </Item>
          </Grid>
        </Grid>
      </RowContainer>

      {/* row 2 */}
      <RowContainer>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Item sx={{ height: "415px", padding: "25px 15px" }}>
              <div className="cards">
                <Box display="flex" alignItems="center">
                  <WarningIcon sx={{ color: "red", fontSize: "30px", marginRight: "10px" }} />
                  <h2 className="p-0" style={{ margin: 0 }}>EXPIRY DATE REMINDERS</h2>
                </Box>
                <Box p={2}>
                  {/* Filter Buttons */}
                  <Box display="flex" justifyContent="center" mb={2} borderBottom={1} borderColor="divider">
                    <Button
                      variant="text"
                      onClick={() => setExpiryType("all")}
                      sx={{
                        color: expiryType === "all" ? "#2596BE" : "inherit",
                        borderBottom: expiryType === "all" ? "2px solid #2596BE" : "none",
                        marginRight: "10px",
                        fontSize: "14px",
                        "&:hover": {
                          backgroundColor: "transparent",
                          color: "#2596BE",
                          borderBottom: "2px solid #2596BE",
                        },
                      }}
                    >
                      All ({allCount})
                    </Button>
                    <Button
                      variant="text"
                      onClick={() => setExpiryType("passportexpiry")}
                      sx={{
                        color: expiryType === "passportexpiry" ? "#2596BE" : "inherit",
                        borderBottom: expiryType === "passportexpiry" ? "2px solid #2596BE" : "none",
                        marginRight: "10px",
                        fontSize: "14px",
                        "&:hover": {
                          backgroundColor: "transparent",
                          color: "#2596BE",
                          borderBottom: "2px solid #2596BE",
                        },
                      }}
                    >
                      Passport Expiry ({counts.passportExpiryCount})
                    </Button>
                    <Button
                      variant="text"
                      onClick={() => setExpiryType("workpermitexpiry")}
                      sx={{
                        color: expiryType === "workpermitexpiry" ? "#2596BE" : "inherit",
                        borderBottom: expiryType === "workpermitexpiry" ? "2px solid #2596BE" : "none",
                        marginRight: "10px",
                        fontSize: "14px",
                        "&:hover": {
                          backgroundColor: "transparent",
                          color: "#2596BE",
                          borderBottom: "2px solid #2596BE",
                        },
                      }}
                    >
                      Work Permit Expiry ({counts.workPermitExpiryCount})
                    </Button>
                    <Button
                      variant="text"
                      onClick={() => setExpiryType("visaexpiry")}
                      sx={{
                        color: expiryType === "visaexpiry" ? "#2596BE" : "inherit",
                        borderBottom: expiryType === "visaexpiry" ? "2px solid #2596BE" : "none",
                        marginRight: "10px",
                        fontSize: "14px",
                        "&:hover": {
                          backgroundColor: "transparent",
                          color: "#2596BE",
                          borderBottom: "2px solid #2596BE",
                        },
                      }}
                    >
                      Visa Expiry ({counts.visaExpiryCount})
                    </Button>
                    <Button
                      variant="text"
                      onClick={() => setExpiryType("medicalexpiry")}
                      sx={{
                        color: expiryType === "medicalexpiry" ? "#2596BE" : "inherit",
                        borderBottom: expiryType === "medicalexpiry" ? "2px solid #2596BE" : "none",
                        fontSize: "14px",
                        "&:hover": {
                          backgroundColor: "transparent",
                          color: "#2596BE",
                          borderBottom: "2px solid #2596BE",
                        },
                      }}
                    >
                      Medical Expiry ({counts.medicalExpiryCount})
                    </Button>
                  </Box>

                  {/* Expiry Date Reminders */}
                  {expiryLoader ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%" padding="20px">
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Box className="notification-container">
                      {expiryDates.length === 0 ? (
                        <Typography variant="body1" color="textSecondary" align="center" paddingTop="100px">
                          No upcoming expiry dates.
                        </Typography>
                      ) : (
                        expiryDates.map((item, index) => (
                          <Box key={index} mb={2}>
                            <Paper
                              elevation={3}
                              sx={{
                                padding: 2,
                                display: "flex",
                                alignItems: "center",
                                borderLeft: "5px solid red",
                                backgroundColor: "#fdf5f5",
                                animation: "pulse 1s infinite"
                              }}>
                              <Box flexGrow={1}>
                                <Typography variant="body1" color="textSecondary"
                                  sx={{ fontWeight: "bold", fontSize: "15px", color: "#ff4d4d" }}>
                                  {item.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                  <span dangerouslySetInnerHTML={{ __html: item.message }}></span>
                                </Typography>
                              </Box>
                            </Paper>
                          </Box>
                        ))
                      )}
                    </Box>
                  )}
                </Box>

              </div>
            </Item>
          </Grid>
          {/* <Grid item xs={3}>
            <Item sx={{ height: "415px", padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">EMPLOYEES BY DEPARTMENT</h2>
              </div>
            </Item>
          </Grid> */}
          <Grid item xs={3}>
            <Item sx={{ height: "415px", padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">SERVICES</h2>
                <span style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginTop: "20px",
                      width: "250px",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      backgroundColor: "#2596BE"
                    }}
                    onClick={handleLeaveFormOpen}
                  >
                    Request Leave Application
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginTop: "20px",
                      width: "250px",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      backgroundColor: "#2596BE"
                    }}
                    onClick={handleClickOpen}
                  >
                    Issue Salary Certificate
                  </Button>
                </span>
              </div>
            </Item>
          </Grid>
        </Grid>
      </RowContainer>

      {/* row 3 */}
      {/* <RowContainer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item sx={{ height: "300px", padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">PERFORMANCE FEEDBACK</h2>
              </div>
            </Item>
          </Grid>
        </Grid>
      </RowContainer> */}

      {/* row 4 */}
      {/* <RowContainer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item sx={{ height: "300px", padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">LEAVE REQUESTS</h2>
              </div>
            </Item>
          </Grid>
        </Grid>
      </RowContainer> */}

      {/* row 5 */}
      {/* <RowContainer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item sx={{ height: "300px", padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">JOB APPLICATIONS</h2>
              </div>
            </Item>
          </Grid>
        </Grid>
      </RowContainer> */}

      {/* Issue Salary Certificate Button */}
      <Dialog open={salaryCertOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Issue Salary Certificate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the reason for issuing the salary certificate.
          </DialogContentText>
          <TextField
            sx={{ marginTop: "25px" }}
            margin="dense"
            id="reason"
            label="Reason"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-between", padding: "20px" }}>
          <Button
            sx={{ backgroundColor: "red", color: "white", "&:hover": { backgroundColor: "darkred" }, width: "80px", height: "35px" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ backgroundColor: "#2596BE", color: "white", "&:hover": { backgroundColor: "#1c7da0" }, width: "80px", height: "35px" }}
            onClick={handleConfirm}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  )
}