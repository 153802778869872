import React from "react"
import PendingLeavesTable from "../../Components/Tables/LeaveMgmtTables/PendingLeavesTable"

function PendingLeaves() {
  return (
    <div>
      <PendingLeavesTable/>
    </div>
  )
}

export default PendingLeaves
