import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import CloseIcon from "@mui/icons-material/Close"
import { Modal, CircularProgress, Divider } from "@mui/material"
import { fetchEmpSalaryDetails } from "../../Actions/commonController"

export default function SalaryDetailsModal({ open, onClose, empId }) {
  const [loader, setLoader] = useState(false)
  const [salaryDetails, setSalaryDetails] = useState(null)
  const [totalSalary, setTotalSalary] = useState("0.00")

  const fetchData = async () => {
    try {
      let APIData = {
        empid: empId,
      }
      if (empId) {
        setLoader(true)
        let { data, status } = await fetchEmpSalaryDetails(APIData)
        if (status === "success") {
          setSalaryDetails(data[0])
          calculateTotalSalary(data[0])
        }
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
      console.error("Error fetching personal details data:", error)
    }
  }

  const calculateTotalSalary = (details) => {
    const total = (
      parseFloat(details.basicsalary || 0) +
      parseFloat(details.housingallowance || 0) +
      parseFloat(details.transportallowance || 0) +
      parseFloat(details.foodallowance || 0) +
      parseFloat(details.mobileallowance || 0) +
      parseFloat(details.otherallowance || 0)
    ).toFixed(2)
    setTotalSalary(total)
  }

  useEffect(() => {
    if (open) fetchData()
  }, [empId, open])

  return (
    <Modal
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      open={open}
      onClose={onClose}
      className="modal"
      keepMounted
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 id="keep-mounted-modal-title" className="name">
            Salary Details
          </h2>
          <button
            type="button"
            onClick={onClose}
            className="close-icon"
          >
            <CloseIcon />
          </button>
        </div>
        <Divider className="divider" />
        <div className="modalBody">
          <p id="keep-mounted-modal-description" className="field">
            Basic Salary
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              salaryDetails?.basicsalary ? `${salaryDetails.basicsalary}/-AED `: "- /-AED"
              
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Housing Allowance
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              salaryDetails?.housingallowance ? `${salaryDetails.housingallowance}/-AED` : "-"
              
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Transportation Allowance
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              salaryDetails?.transportallowance ? `${salaryDetails.transportallowance}/-AED` : "-"
              
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Food Allowance
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              salaryDetails?.foodallowance ? `${salaryDetails.foodallowance}/-AED` : "-"
              
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Mobile Allowance
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              salaryDetails?.mobileallowance ? `${salaryDetails.mobileallowance}/-AED` : "-"
              
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Other Allowance
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              salaryDetails?.otherallowance ? `${salaryDetails.otherallowance}/-AED` : "-"
              
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Total Salary
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              totalSalary ? `${totalSalary}/-AED` : "-"
              
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Account Number
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              salaryDetails?.accnumber ? salaryDetails.accnumber : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            IBAN
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              salaryDetails?.iban ? salaryDetails.iban : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Bank Name
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              salaryDetails?.salarybankname ? salaryDetails.salarybankname : "-"
            )}
          </h4>
        </div>
      </div>
    </Modal>
  )
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props
  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  )
})

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
}