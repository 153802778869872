import React from 'react'
import Sidebar from '../../Components/sideBar'

function MonthlyPayroll() {
  return (
    <h1>
      Monthly Payroll here
    </h1>
  )
}

export default MonthlyPayroll
