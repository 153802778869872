import axios from "axios"

export const SetAuthorizationHeader = async(token) => {
    await removeAuthorizationHeader()

    axios.interceptors.request.use(
        (config) => {
            if (token) {
                config.headers.Authorization = `Bearer ${token}`
            }
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )
}

export const removeAuthorizationHeader = async() => {
    axios.interceptors.request.clear()
}