import { saveAs } from "file-saver"
import html2pdf from "html2pdf.js"
// import jsPDF from "jspdf"
// import html2canvas from "html2canvas"
// import htmlToDocx from "html-to-docx"
import fintrekMarketingConsultanciesLLCLetterhead from "../../assets/salaryCertifTemplates/fintrekMarketingConsultanciesLLCLetterhead.png"
import fintrekMarketingConsultancyLetterhead from "../../assets/salaryCertifTemplates/fintrekMarketingConsultancyLetterhead.png"
import fintrekMarketingLetterhead from "../../assets/salaryCertifTemplates/fintrekMarketingLetterhead.png"
import neowizMarketingManagementLetterhead from "../../assets/salaryCertifTemplates/neowizMarketingManagementLetterhead.png"
import wizhullLetterhead from "../../assets/salaryCertifTemplates/wizhullLetterhead.png"
import { toast } from "react-toastify"


const getLetterHead = (sponsorname) => {
    switch (sponsorname) {
        case "Fintrek Marketing":
            return fintrekMarketingLetterhead
        case "Fintrek Marketing Consultancy":
            return fintrekMarketingConsultancyLetterhead
        case "Fintrek Marketing Consultancies LLC":
            return fintrekMarketingConsultanciesLLCLetterhead
        case "Neowiz Marketing Management":
            return neowizMarketingManagementLetterhead
        case "Wizhull Real Estate Mortgage Broker":
            return wizhullLetterhead
        default:
            return fintrekMarketingLetterhead
    }
}

export const GenerateTermationLetter = async (employee, today, title, sponsorname, format) => {

    try {
        const letterhead = getLetterHead(employee.sponsorname)

        const content = `
    <html>
        <body>
            <div id="termination-letter-content" style="position: relative; font-family: 'Calibri', serif; font-size: 11pt; line-height: 1.5; width: 210mm; height: 297mm;">
            <!-- Background Letterhead -->
        
                <img src="${letterhead}" alt="Header" style="position: absolute; width: 210mm; height: 297mm; top: 0; left: 0; z-index: 0;">

                <!-- Content on top of the Letterhead -->
                <div style="position: relative; z-index: 1; padding-top: 50mm; padding-left: 20mm; padding-right: 20mm; padding-bottom: 40mm;">
                    <div style="margin-bottom: 20px; text-align: left">
                        <p style="margin: 0; font-family: 'Calibri', sans-serif; font-size: 11pt;">
                            ${today}
                        </p>
                        <p style="margin: 0; font-family: 'Calibri', sans-serif; font-size: 11pt; margin-top: 30px">
                            <strong>Name:</strong> ${employee.name}<br>
                            <strong>Name:</strong> ${employee.designation}<br>
                            <strong>Name:</strong> ${employee.passportno}<br>
                            <strong>Name:</strong> ${employee.nationality}
                        </p>
                    </div>
                    
                    <p style="font-family: 'Calibri', serif; font-size: 11pt; margin-top: 20px; text-align: justify;">
                        Dear ${title} ${employee.name},
                    </h2>

                    <p style="font-family: 'Calibri', serif; font-size: 11pt; margin-top: 20px; text-align: justify;">
                        We regret to inform you that you have not successfully completed your probation due to your 
                        low performance.
                    </p>

                    <p style="font-family: 'Calibri', serif; font-size: 11pt; margin-top: 20px; text-align: justify;">
                        As a result, we have decided not to continue your services beyond your probationary period. Hence, your last working 
                        day will be ${employee.lwd}.                    
                    </p>
                    
                    <p style="font-family: 'Calibri', serif; font-size: 11pt; margin-top: 20px; text-align: justify;">
                        You are requested to hand over all company property in your possession. You will be paid up to 
                        and including your last date of service. If you are not contactable during the visa cancellation 
                        process you would be marked as absconding.                    
                    </p>

                    <table style="width: 100%; margin-top: 40px;">
                        <tr>
                            <td style="font-family: 'Times', serif; font-size: 11pt; vertical-align: top;">
                                Yours sincerely,<br><br><br><br>
                                <strong>Human Resources Department</strong><br>
                                <strong>${employee.sponsorname}</strong>
                            </td>
                            <td style="font-family: 'Times', serif; font-size: 11pt; text-align: right; vertical-align: top;">
                                I acknowledge the receipt of this letter.<br><br><br>
                                <strong>Name:</strong> ..................................................<br><br>
                                <strong>Signature:</strong> ...............................................<br><br>
                                <strong>Date:</strong> ..................................................
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </body>
    </html>
    `

        if (format === "pdf") {
            const element = document.createElement('div')
            element.innerHTML = content

            html2pdf()
                .from(element)
                .set({
                    margin: 0,
                    filename: `${employee.name}_Termination_Letter.pdf`,
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
                })
                .save()
                .then(() => {
                    document.body.removeChild(element)
                })
                .catch(error => {
                    console.error('Error generating PDF:', error)
                })
        } else if (format === "word") {
            toast.error("This feature is not available right now!")
        }
    } catch (error) {
        console.error("Error in GenerateTerminationLetter:", error)
    }
}