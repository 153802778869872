import React from 'react'
import Sidebar from '../../Components/sideBar'

function Attendance() {
  return (
    <>
      <h1>Attendance Page</h1>
    </>
  )
}

export default Attendance
