import React, { useState, useEffect } from "react"
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  CircularProgress,
  MenuItem,
  Select,
} from "@mui/material"
import PaginationTable from "../../Pagination"
import {
  fetchEmpPaySlips,
  fetchEmpPaySlipsCount
} from "../../../Actions/commonController"
import { useAuth } from "../../../Context/authContext"
import SearchBar from "../../SearchBar"
import { FormatDate } from "../../Utilities/DateFormatter"

const columns = [
  { id: "empid", label: "EMP ID", minWidth: 100 },
  { id: "name", label: "NAME", minWidth: 150 },
  { id: "dateofjoin", label: "DATE OF JOIN", minWidth: 150 },
  { id: "lwd", label: "LAST WORKING DAY", minWidth: 150 },
  { id: "basicsalary", label: "BASIC SALARY PER CONTRACT", minWidth: 150 },
  { id: "endOfService", label: "GRATUITY", minWidth: 150 },
]

export default function PaySlipsTable() {

  const [page, setPage] = useState(1)
  const [quantity, setQuantity] = useState(10)
  const [totalItems, setTotalItems] = useState(0)
  const [initialLoad, setInitialLoad] = useState(false)
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const { eid, logout } = useAuth()


  const initialization = async () => {
    try {
      let APIData = {
        search: searchTerm,
      }
      setLoader(true)
      let { status, data } = await fetchEmpPaySlipsCount(APIData, logout)

      if (status === "success") {
        setTotalItems(data)
        await loadData()
      }
    } catch (error) {
      console.error("Error in loadData function", error)
    }
  }
  const loadData = async () => {
    try {

      let APIData = {
        quantity: quantity,
        page: page,
        search: searchTerm,
      }
      setLoader(true)
      const { data, status } = await fetchEmpPaySlips(APIData, logout)
      if (status === "success") {
        setData(data)
      } else {
        console.error("Error fetching pay slips", status)
      }
    } catch (error) {
      console.error("Error in loadData function", error)
    }
    setLoader(false)
  }

  const handleSearch = async (searchData, type) => {
    setSearchTerm(searchData)
  }

  const handlePageClick = async (newPage) => {
    setPage(newPage)
  }

  const handleItemsPerPageChange = async (newRowsPerPage) => {
    setQuantity(newRowsPerPage)
    setPage(1)
  }

  const handleSalaryChange = (empid, selectedContract) => {
    setData(prevData =>
      prevData.map(item =>
        item.empid === empid
          ? { ...item, basicsalary: selectedContract.basic, endOfService: selectedContract.gratuity }
          : item
      )
    )
  }

  useEffect(() => {
    if (initialLoad) loadData()
    setInitialLoad(true)
  }, [page, quantity])

  useEffect(() => {
    initialization()
  }, [searchTerm])

  return (
    <Paper className="pagePaper">
      <span className="info">
        <h5>Pay Slips</h5>
        <>
          <SearchBar searchText={"Search by name..."} onSearch={handleSearch} searchType={"name"} />
          <SearchBar searchText={"Search by empid..."} onSearch={handleSearch} searchType={"empid"} />
        </>
      </span>

      <div className="row" style={{
        paddingTop: "60px",
      }}>
        <div className="col-md-12">
          <TableContainer
            sx={{
              overflowY: "auto",
              maxHeight: "500px"
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      className="tableHeader"
                      key={column.id}
                      align="center"
                      style={{
                        minWidth: column.minWidth
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loader ? (
                  <TableRow>
                    <TableCell colSpan={5} style={{ padding: "30px 0", textAlign: "center" }}>
                      <CircularProgress size={100} />
                    </TableCell>
                  </TableRow>
                ) : data && data.length > 0 ? (data.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{item.empid}</TableCell>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">{FormatDate(item.dateofjoin)}</TableCell>
                    <TableCell align="center">{item.lwd ? `${FormatDate(item.lwd)}` : '-'}</TableCell>
                    <TableCell align="center">
                      <Select
                        value={item.selectedContract || ""}
                        onChange={(e) => handleSalaryChange(item.empid, item.contracts.find(contract => contract.basic === e.target.value))}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Select a contract
                        </MenuItem>
                        {item.contracts.map((contract, index) => (
                          <MenuItem key={index} value={contract.basic}>
                            {contract.label}: {contract.basic} /-AED
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell align="center">{item.endOfService ? `${item.endOfService}/- AED` : '0/- AED'}</TableCell>
                  </TableRow>
                ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} style={{ padding: "30px 0", textAlign: "center" }}>
                      No data found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>

            </Table>
          </TableContainer>
        </div>
      </div>

      <PaginationTable
        totalItems={totalItems}
        itemsPerPage={quantity}
        onPageChange={handlePageClick}
        onItemsPerPageChange={handleItemsPerPageChange}
        currentPage={page}
      />
    </Paper>
  )
}