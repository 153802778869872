import React, { useState, useEffect } from "react"
import {
  Grid,
  Paper,
  Box,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  Autocomplete,
  Modal,
  Typography,
  styled,
} from "@mui/material"
import {
  deleteDocs,
  fetchSingleEmpRecord,
  updateEmpRecords,
  fetchLineManager
} from "../../Actions/commonController"
import {
  useParams,
  Link,
  useNavigate
} from "react-router-dom"
import DragNDrop from "../dragNDrop"
import { toast } from "react-toastify"
import DeleteIcon from "@mui/icons-material/Delete"
import nationalities from "../../json/nationalities.json"
import { useAuth } from "../../Context/authContext"
import { AdjustFilename } from "../Utilities/FilenameAdjuster"
import { Validate } from "../Validations/EditEmployeeValidation"
import { FormatDate } from "../Utilities/DateFormatter"
import ResponsiveDatePicker from "../DatePicker/ResponsiveDatePicker"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

const initialValue = {
  name: "",
  email: "",
  gender: "",
  nationality: "",
  dob: "",
  age: "",
  passportno: "",
  passportexpiry: "",
  passportstatus: "",
  contactuae: "",
  maritalstatus: "",
  religion: "",
  photoloc: [],
  eidno: "",
  gpssa: "",
  insuredno: "",
  insureddate: "",
  dateofjoin: "",
  designation: "",
  officeloc: "",
  bankname: "",
  dept: "",
  division: "",
  hiredfrom: "",
  sourcecode: "",
  linemanager: "",
  teamleader: "",
  visaid: "",
  dateofjoinmol: "",
  workpermitnumber: "",
  workpermitstartdate: "",
  workpermitexpiry: "",
  personalcodemohre: "",
  contracttype: "",
  sponsorname: "",
  labourcard: "",
  visafileno: "",
  visadesignation: "",
  visatype: "",
  visaexpiry: "",
  visacancellation: "",
  contacthome: "",
  emergencycontactuaename: "",
  emergencycontactuaeno: "",
  emergencycontacthomename: "",
  emergencycontacthomeno: "",
  companysimnumber: "",
  simissuedate: "",
  simlastdate: "",
  simplan: "",
  medicalprovider: "",
  medicalexpiry: "",
  medicalcardno: "",
  basicsalary: "",
  housingallowance: "",
  transportallowance: "",
  foodallowance: "",
  mobileallowance: "",
  otherallowance: "",
  totalsalary: "",
  contractsalary: "",
  accnumber: "",
  iban: "",
  salarybankname: "",
  folderid: "",
  remarks: "",
  contractupdate: [],
  docslist: [],
  docstatus: "",
}

const documentMappingInitial = {
  photo: {
    name: "Photo",
    keywords: ["photo"]
  },
  passportCopy: {
    name: "Passport Copy",
    keywords: ["passport"]
  },
  attestedDegreeCertificate: {
    name: "Attested Degree Certificate or Highest Qualification Certificate",
    keywords: ["attested", "degree", "qualification"]
  },
  visitVisaCopy: {
    name: "Visit Visa Copy or Visa Cancellation Documents",
    keywords: ["visit visa", "visa cancellation", "current visa", "visa copy", "employment visa"]
  },
  experienceCertificate: {
    name: "Experience Certificate or Acceptance of Resignation Letter",
    keywords: ["experience certificate", "experience letter", "resignation"]
  },
  emiratesID: {
    name: "Emirates ID",
    keywords: ["emirates id", "eid"]
  },
  validHomeCountryID: {
    name: "Valid Home Country ID",
    keywords: ["home country id", "pakistan", "india", "egypt"]
  },
  cv: {
    name: "CV",
    keywords: ["cv"]
  },
  tradeLicense: {
    name: "Trade License",
    keywords: ["trade license"]
  },
  policeClearance: {
    name: "Police Clearance",
    keywords: ["police", "clearance"]
  },
  eMF: {
    name: "eMF",
    keywords: ["emf"]
  },
  sponsorPassport: {
    name: "Sponsor Passport",
    keywords: ["sponsor passport"]
  },
  sponsorVisaCopy: {
    name: "Sponsor Visa Copy",
    keywords: ["sponsor visa"]
  },
  noc: {
    name: "NOC in Arabic addressing Fintrek Marketing",
    keywords: ["noc", "fintrek marketing"]
  },
}

const defaultDocumentList = Object.keys(documentMappingInitial)

const TableWrapper = styled(TableContainer)(({ theme }) => ({
  overflowY: "scroll",
  margin: "0",
}))

export default function EditEmployeeForm({ status }) {

  const [formValue, setFormValue] = useState(initialValue)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [uploadedDocs, setUploadedDocs] = useState([])
  const [uploadedImage, setUploadedImage] = useState("")
  const [lineManagers, setLineManagers] = useState([])
  const [overwriteModal, setOverwriteModal] = useState(false)
  const [loader, setLoader] = useState(false)
  const [changeLogs, setChangeLogs] = useState({})
  const [formValidation, setFormValidation] = useState({})
  const [empStatus, setEmpStatus] = useState("")
  const [newTotalSalary, setNewTotalSalary] = useState(null)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const navigate = useNavigate()
  const { empid } = useParams()
  const { eid, tier } = useAuth()
  const [newDoc, setNewDoc] = useState("")
  const [requiredDocuments, setRequiredDocuments] = useState([])
  const [tempUploadedFiles, setTempUploadedFiles] = useState([])
  const [tempUploadedDocs, setTempUploadedDocs] = useState([])

  const {
    name, email, gender, nationality, dob, age, passportno, passportstatus, passportexpiry,
    contactuae, maritalstatus, religion, photoloc, hiredfrom, dateofjoin, designation, officeloc,
    bankname, dept, division, sourcecode, linemanager, teamleader, visaid, dateofjoinmol,
    workpermitnumber, workpermitstartdate, workpermitexpiry, personalcodemohre, contracttype, sponsorname, labourcard,
    visafileno, visadesignation, visatype, visaexpiry, visacancellation, eidno, gpssa, insuredno,
    insureddate, contacthome, emergencycontactuaename, emergencycontactuaeno, emergencycontacthomename,
    emergencycontacthomeno, companysimnumber, simissuedate, simlastdate, simplan, medicalprovider,
    medicalexpiry, medicalcardno, basicsalary, housingallowance, transportallowance, foodallowance,
    mobileallowance, otherallowance, totalsalary, contractsalary, accnumber, iban, salarybankname,
    folderid, contractupdate, remarks, docslist, docstatus
  } = formValue || {}

  const fetchData = async (empid, setFormValue) => {
    try {
      let APIData = { empid: empid }

      if (empid) {
        let { data, status, docs } = await fetchSingleEmpRecord(APIData)

        if (status === "success") {

          const calculateAge = (dob) => {
            if (!dob) return ''
            const [day, month, year] = dob.split('/')
            const birthDate = new Date(`${year}-${month}-${day}`)
            const today = new Date()
            let age = today.getFullYear() - birthDate.getFullYear()
            const monthDiff = today.getMonth() - birthDate.getMonth()
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
              age--
            }
            return age.toString()
          }

          const formattedDob = data.dob ? FormatDate(data.dob) : ""
          const age = calculateAge(formattedDob)

          const docslist = data.docslist ? data.docslist.split(";").map((doc) => doc.trim()) : []

          const formattedData = {
            ...data,
            dob: formattedDob,
            age: age,
            passportexpiry: data.passportexpiry ? FormatDate(data.passportexpiry) : "",
            dateofjoin: data.dateofjoin ? FormatDate(data.dateofjoin) : "",
            dateofjoinmol: data.dateofjoinmol ? FormatDate(data.dateofjoinmol) : "",
            workpermitstartdate: data.workpermitstartdate ? FormatDate(data.workpermitstartdate) : "",
            workpermitexpiry: data.workpermitexpiry ? FormatDate(data.workpermitexpiry) : "",
            visaexpiry: data.visaexpiry ? FormatDate(data.visaexpiry) : "",
            medicalexpiry: data.medicalexpiry ? FormatDate(data.medicalexpiry) : "",
            visacancellation: data.visacancellation ? FormatDate(data.visacancellation) : "",
            simissuedate: data.simissuedate ? FormatDate(data.simissuedate) : "",
            simlastdate: data.simlastdate ? FormatDate(data.simlastdate) : "",
            insureddate: data.insureddate ? FormatDate(data.insureddate) : "",
            docslist: docslist,
            contractupdate: data.contractupdate ? JSON.parse(data.contractupdate).map(contract => ({
              ...contract,
              date: contract.date ? FormatDate(contract.date) : ""
            })) : [],
          }

          setFormValue(formattedData)
          setUploadedDocs(docs || [])
          setUploadedImage(data.photoloc)
          setEmpStatus(data.empstatus)
          setRequiredDocuments(formattedData.docslist)
        } else {
          console.error("Fetch status not successful:", status)
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error)
    }
  }

  const getLineManagers = async () => {
    try {
      let APIData = {
        empid: eid,
        dept: dept || "",
      }
      const { status, data } = await fetchLineManager(APIData)
      if (status === "success") {
        setLineManagers(data)
      } else {
        console.error("Failed to fetch line managers", status)
      }
    } catch (error) {
      console.error("Error fetching line managers", error)
    }
  }

  useEffect(() => {
    if (empid != "" && eid != "" && tier != "") fetchData(empid, setFormValue)
  }, [empid, eid, tier])


  useEffect(() => {
  }, [empStatus])


  useEffect(() => {
    getLineManagers()
  }, [])

  useEffect(() => {
    const totalSalary = (
      parseFloat(basicsalary || "0") +
      parseFloat(housingallowance || "0") +
      parseFloat(transportallowance || "0") +
      parseFloat(foodallowance || "0") +
      parseFloat(mobileallowance || "0") +
      parseFloat(otherallowance || "0")
    ).toFixed(2)

    setFormValue(prevState => ({
      ...prevState,
      totalsalary: totalSalary.toString()
    }))
  }, [
    basicsalary,
    housingallowance,
    transportallowance,
    foodallowance,
    mobileallowance,
    otherallowance,
    formValue.contractupdate
  ])

  useEffect(() => {
    if (Array.isArray(formValue.docslist) && formValue.docslist.length > 0) {
      setRequiredDocuments(formValue.docslist)
    } else {
      setRequiredDocuments([])
    }
  }, [formValue.docslist])

  useEffect(() => {
    const updatedStatus = checkDocumentsStatus([...tempUploadedFiles, ...uploadedDocs], requiredDocuments)
    setFormValue((prevState) => ({
      ...prevState,
      docstatus: updatedStatus,
    }))
  }, [uploadedDocs, requiredDocuments, tempUploadedFiles])

  const checkDocumentsStatus = (uploadedFiles, requiredDocs) => {
    const uploadedFileNames = uploadedFiles.map((file) => normalizeString(file.filename || file.name))

    const missingDocuments = requiredDocs.filter(docName => {
      const normalizedDocName = normalizeString(docName)
      const docEntry = Object.values(documentMappingInitial).find(doc => normalizeString(doc.name) === normalizedDocName)

      if (!docEntry) {
        console.warn(`Document key ${docName} not found in documentMapping`)
        return false
      }

      const keywords = (docEntry.keywords || []).map(normalizeString)
      return !uploadedFileNames.some(fileName => keywords.some(keyword => fileName.includes(keyword)))
    })

    const docStatus = missingDocuments.length === 0 ? "Submitted" : "Pending"
    return docStatus
  }

  const closeOverwiteModal = () => { setOverwriteModal(false) }

  function parseDate(dateStr) {
    const parts = dateStr.split('/')
    if (parts.length !== 3) return null
    const [day, month, year] = parts.map(part => parseInt(part, 10))
    if (!day || !month || !year) return null
    return new Date(year, month - 1, day)
  }

  function calculateAge(dob) {
    const today = new Date()
    const birthDate = new Date(dob)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  }

  const handleChange = (e) => {
    let { name, value } = e.target

    if (name === "name") {
      const alphabetRegex = /^[A-Za-z\s]*$/
      if (!alphabetRegex.test(value)) {
        return
      }
    }

    const numberFields = [
      "contactuae",
      "contacthome",
      "emergencycontactuaename",
      "emergencycontactuaeno",
      "emergencycontacthomename",
      "emergencycontacthomeno",
      "companysimnumber",
      "basicsalary",
      "housingallowance",
      "transportallowance",
      "foodallowance",
      "mobileallowance",
      "otherallowance",
      "contractsalary",
    ]

    if (numberFields.includes(name)) {
      const numberRegex = /^[0-9+()\-\s]*$/
      if (!numberRegex.test(value)) {
        return
      }
    }

    const calculateAge = (dob) => {
      if (!dob) return ''
      const [day, month, year] = dob.split('/')
      const birthDate = new Date(`${year}-${month}-${day}`)
      const today = new Date()
      let age = today.getFullYear() - birthDate.getFullYear()
      const monthDiff = today.getMonth() - birthDate.getMonth()
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }
      return age.toString()
    }

    if (name === "dob") {
      const parsedDate = parseDate(value)
      if (parsedDate) {
        const calculatedAge = calculateAge(parsedDate)
        setFormValue((prevValues) => ({
          ...prevValues,
          dob: value,
          age: calculatedAge.toString(),
        }))
        if (calculatedAge < 18) {
          toast.error("Employee must be at least 18 years old")
        }
      } else {
        setFormValue((prevValues) => ({
          ...prevValues,
          dob: value,
          age: "",
        }))
      }
    } else {
      setFormValue((prevValues) => ({
        ...prevValues,
        [name]: value,
      }))
    }

    if (!changeLogs[name]) {
      setChangeLogs((prevLogs) => ({
        ...prevLogs,
        [name]: {
          before: formValue[name],
          after: value,
        },
      }))
    } else {
      setChangeLogs((prevLogs) => ({
        ...prevLogs,
        [name]: {
          ...prevLogs[name],
          after: value,
        },
      }))
    }
  }

  const handleConfirmChange = () => {

    const currentTotalSalary = (
      parseFloat(basicsalary || 0) +
      parseFloat(housingallowance || 0) +
      parseFloat(transportallowance || 0) +
      parseFloat(foodallowance || 0) +
      parseFloat(mobileallowance || 0) +
      parseFloat(otherallowance || 0)
    ).toFixed(2)

    const difference = parseFloat(newTotalSalary) - parseFloat(currentTotalSalary)

    if (!isNaN(difference)) {
      setFormValue((prevState) => ({
        ...prevState,
        otherallowance: (parseFloat(prevState.otherallowance || 0) + difference),
        totalsalary: parseFloat(newTotalSalary),
      }))
    } else {
      console.error("Invalid difference calculation.")
    }

    setConfirmationModal(false)
  }

  const handleBlur = (e) => {
    const { name, value } = e.target

    if (name === "totalsalary") {
      const parsedValue = parseFloat(value)
      if (!isNaN(parsedValue)) {
        setNewTotalSalary(parsedValue)
        setConfirmationModal(true)
      }
      else {
        console.error("Invalid total salary input.")
      }
    } else {
      setFormValue((prevValues) => ({
        ...prevValues,
        [name]: value,
      }))
    }
  }

  const handleCancelChange = () => {
    setConfirmationModal(false)
  }

  const handleNationalityChange = (event, newValue) => {
    setFormValue({
      ...formValue,
      nationality: newValue
    })
  }

  const handleDrop = (acceptedFiles) => {
    let newTempUploadedFiles = [...tempUploadedFiles]
    let newTempUploadedDocs = [...tempUploadedDocs]

    acceptedFiles.forEach(file => {
      let adjustedFilename = AdjustFilename(file.name, tempUploadedFiles)
      let newFile = new File([file], adjustedFilename, { type: file.type })
      newTempUploadedFiles.push(newFile)
      newTempUploadedDocs.push({ filename: adjustedFilename })
    })

    setTempUploadedFiles(newTempUploadedFiles)
    setTempUploadedDocs(newTempUploadedDocs)
    updateDocumentStatus(newTempUploadedFiles.concat(uploadedDocs))
  }

  const handleRemoveFile = (index) => {
    const updatedFiles = [...tempUploadedFiles]
    updatedFiles.splice(index, 1)
    setTempUploadedFiles(updatedFiles)
    setTempUploadedDocs(updatedFiles.map((file) => ({ filename: file.name })))
    updateDocumentStatus(updatedFiles)
  }

  const handleSubmit = async () => {

    let check = 0
    tempUploadedFiles.forEach((item) => {
      if (uploadedDocs.some(doc => doc.filename === item.name)) check++
    })

    if (check > 0) return setOverwriteModal(true)

    const logsObject = {}
    Object.entries(changeLogs).forEach(([key, { before, after }]) => {
      logsObject[key] = { before, after }
    })

    const logsJson = JSON.stringify(logsObject)
    let checkValidate = Validate(formValue)
    setFormValidation(checkValidate)

    if (Object.keys(checkValidate).length > 0) {
      toast.error("Errors: " + Object.entries(checkValidate).map(([field, message]) => `${message}`).join(", "))
      return
    }

    let updatedDocslist = formValue.docslist
    updatedDocslist = updatedDocslist.filter(doc => requiredDocuments.includes(doc))
    requiredDocuments.forEach(doc => {
      if (!updatedDocslist.includes(doc)) {
        updatedDocslist.push(doc)
      }
    })

    setUploadedFiles(tempUploadedFiles)
    setUploadedDocs([...uploadedDocs, ...tempUploadedDocs])

    setTempUploadedFiles([])
    setTempUploadedDocs([])

    handleFinalSubmit(logsJson, updatedDocslist, tempUploadedDocs)
  }

  const handleFinalSubmit = async (logsJson, updatedDocslist, tempUploadedDocs) => {
    try {

      if (age < 18) {
        toast.error("Employee must be at least 18 years old")
        return
      }

      if (!Array.isArray(requiredDocuments)) {
        console.error("requiredDocuments is not an array")
        return
      }

      setLoader(true)
      let APIData = {
        ...formValue,
        otherallowance: otherallowance || "0",
        totalsalary: totalsalary || "0",
        contractupdate: Array.isArray(formValue.contractupdate) ? JSON.stringify(formValue.contractupdate.map(value => value || "0")) : "[]",
        logs: logsJson,
        empid: empid,
        author: eid,
        docslist: updatedDocslist.join("; "),
      }

      let APIFiles = {
        documents: tempUploadedFiles,
        photoloc: photoloc,
      }

      let checkValidate = Validate(APIData)
      setFormValidation(checkValidate)
      if (Object.entries(checkValidate).length > 0) {
        toast.error("Error in the following fields: " + Object.entries(checkValidate).map(([field, message]) => `${message}`).join(", "))
        return
      }

      const { status } = await updateEmpRecords(APIData, APIFiles)
      if (status === "success") {
        toast.success("The record has been edited!")
        if (empStatus === "New") {
          navigate("/employee/new-employee-list")
        } else {
          navigate("/employee/existing-employee-list")
        }
        fetchData(empid, setFormValue)
        setUploadedFiles([])
        setUploadedImage([])
        setChangeLogs({})
      } else {
        toast.error("The record is not edited!")
      }
    } catch (error) {
      console.error("Error updating employee record:", error)
    } finally {
      setLoader(false)
    }
  }

  const handleDeleteDocument = async (fname) => {
    try {

      let APIData = {
        folderid: folderid,
        filename: fname
      }
      let { status } = await deleteDocs(APIData)
      if (status === "success") {
        toast.success("Following document has been deleted: " + fname)
        fetchData(empid, setFormValue)
        setUploadedFiles([])
      }
    } catch (err) {
      console.error("Error updating employee record:", err.message)
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file && isValidFileType(file)) {
      setFormValue({ ...formValue, photoloc: file.name })
    } else {
      console.warn("Invalid file selected")
    }
  }

  const isValidFileType = (file) => {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"]
    return allowedTypes.includes(file.type)
  }

  const handleNavigateToTrackHistory = () => {
    navigate(`/employee/track-history/${empid}`)
  }

  const incrementContract = () => {

    const today = new Date()
    const formattedData = today.toLocaleDateString("en-GB")

    setFormValue(prevState => ({
      ...prevState,
      contractupdate: [
        ...prevState.contractupdate,
        {
          date: formattedData,
          basic: "0",
          housing: "0",
          transport: "0",
          food: "0",
          mobile: "0",
          allowance: "0",
          contracts: "0",
          total: "0",
        }
      ]
    }))
  }

  const handleContractUpdate = (index) => (event) => {
    let { name, value } = event.target
    let newContractupdate = [...contractupdate]
    let key = name.replace(`contractupdate${index}.`, '')
    newContractupdate[index][key] = value

    if (key === 'date') {
      newContractupdate[index].date = value.split('-').reverse().join('/')
    }

    const newTotal = [
      newContractupdate[index].basic,
      newContractupdate[index].housing,
      newContractupdate[index].transport,
      newContractupdate[index].food,
      newContractupdate[index].mobile,
      newContractupdate[index].allowance,
      newContractupdate[index].contracts
    ].reduce((acc, curr) => acc + parseFloat(curr || 0), 0).toFixed(2)

    newContractupdate[index].total = newTotal

    setFormValue({ ...formValue, contractupdate: newContractupdate })
  }

  const handleContractRemove = index => {
    setFormValue(prevState => ({
      ...prevState,
      contractupdate: prevState.contractupdate.filter((_, i) => i !== index)
    }))
  }

  useEffect(() => {
    setRequiredDocuments(defaultDocumentList)
  }, [])

  const normalizeString = (str) => {
    if (typeof str !== "string") return ""
    const fileNameWithoutExtension = str.replace(/\.[^/.]+$/, "")
    return fileNameWithoutExtension.trim().toLowerCase().replace(/[^a-z0-9]/g, '')
  }

  const isDocumentUploaded = (docName) => {
    const normalizedDocName = normalizeString(docName)

    const docEntry = Object.values(documentMappingInitial).find(doc => normalizeString(doc.name) === normalizedDocName)

    if (docEntry) {
      const keywords = docEntry.keywords.map(normalizeString)

      const isUploadedByKeywords = uploadedDocs.some((uploadedDoc) => {
        const fileNameLower = normalizeString(uploadedDoc.filename)
        return keywords.some((keyword) => fileNameLower.includes(keyword))
      }) || tempUploadedDocs.some((uploadedDoc) => {
        const fileNameLower = normalizeString(uploadedDoc.filename)
        return keywords.some((keyword) => fileNameLower.includes(keyword))
      }) || tempUploadedFiles.some((uploadedFile) => {
        const fileNameLower = normalizeString(uploadedFile.name)
        return keywords.some((keyword) => fileNameLower.includes(keyword))
      })

      const isExactMatch = uploadedDocs.some((uploadedDoc) => {
        const fileNameLower = normalizeString(uploadedDoc.filename)
        return fileNameLower === normalizedDocName
      }) || tempUploadedDocs.some((uploadedDoc) => {
        const fileNameLower = normalizeString(uploadedDoc.filename)
        return fileNameLower === normalizedDocName
      }) || tempUploadedFiles.some((uploadedFile) => {
        const fileNameLower = normalizeString(uploadedFile.name)
        return fileNameLower === normalizedDocName
      })

      return isUploadedByKeywords || isExactMatch
    } else {
      const isExactMatch = uploadedDocs.some((uploadedDoc) => {
        const fileNameLower = normalizeString(uploadedDoc.filename)
        return fileNameLower === normalizedDocName
      }) || tempUploadedDocs.some((uploadedDoc) => {
        const fileNameLower = normalizeString(uploadedDoc.filename)
        return fileNameLower === normalizedDocName
      }) || tempUploadedFiles.some((uploadedFile) => {
        const fileNameLower = normalizeString(uploadedFile.name)
        return fileNameLower === normalizedDocName
      })

      return isExactMatch
    }
  }

  const addRequiredDocument = () => {
    if (newDoc.includes(";")) {
      toast.error("Semicolons are not allowed in document names.")
    } else if (newDoc && !requiredDocuments.includes(newDoc)) {
      const newKey = newDoc
      const updateDocuments = [...requiredDocuments, newKey]
      setRequiredDocuments(updateDocuments)
      setNewDoc("")
      updateDocumentStatus([...tempUploadedFiles, ...uploadedDocs])
    }
  }

  const removeRequiredDocument = (docKey) => {
    const updateDocuments = requiredDocuments.filter((doc) => doc !== docKey)
    setRequiredDocuments(updateDocuments)
    updateDocumentStatus([...tempUploadedFiles, ...uploadedDocs])
  }

  const updateDocumentStatus = (uploadedFiles) => {
    const docStatus = checkDocumentsStatus(uploadedFiles, requiredDocuments)
    setFormValue(prevState => ({
      ...prevState,
      docstatus: docStatus,
    }))
  }

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
          <Button variant="contained" color="primary" onClick={handleNavigateToTrackHistory}>
            View Track History
          </Button>
        </Box>

        {/* row 1 */}
        <Box className="itemBox">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className="itemPaper">
                <h3 className="cardTitle">PERSONAL INFORMATION</h3>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={2} lg={2} xl={1}>
                    <div className="label">Emp ID</div>
                    <TextField variant="standard" name="empid" value={empid} onChange={handleChange} fullWidth disabled />
                  </Grid>
                  <Grid item xs={12} sm={8} md={5} lg={5} xl={3}>
                    <div className={`label ${formValidation && formValidation.name ? formValidation.name : ""}`}>Full Name *</div>
                    <TextField className={`TextField ${formValidation && formValidation.name ? formValidation.name : ""}`} variant="standard" name="name" value={name} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={7} md={5} lg={5} xl={3}>
                    <div className={`label ${formValidation && formValidation.email ? formValidation.email : ""}`}>E-mail *</div>
                    <TextField className={`TextField ${formValidation && formValidation.email ? formValidation.email : ""}`} variant="standard" name="email" value={email} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
                    <div className={`label ${formValidation && formValidation.contactuae ? formValidation.contactuae : ""}`}>Contact No. *</div>
                    <TextField className={`TextField ${formValidation && formValidation.contactuae ? formValidation.contactuae : ""}`} variant="standard" name="contactuae" value={contactuae} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={7} md={3} lg={3} xl={2}>
                    <div className={`label ${formValidation && formValidation.dob ? formValidation.dob : ""}`}>Date of Birth *</div>
                    <ResponsiveDatePicker
                      name="dob"
                      className="employeeForm"
                      defValue={dob}
                      variant="standard"
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} md={2} lg={1} xl={1}>
                    <div className={`label ${formValidation && formValidation.age ? formValidation.age : ""}`}>Age *</div>
                    <TextField className={`TextField ${formValidation && formValidation.age ? formValidation.age : ""}`} variant="standard" name="age" value={age} onChange={handleChange} fullWidth disabled />
                  </Grid>
                  <Grid item xs={12} sm={7} md={4} lg={3} xl={2}>
                    <div className={`label ${formValidation && formValidation.nationality ? formValidation.nationality : ""}`}>Nationality *</div>
                    <Autocomplete
                      disablePortal
                      name="nationality"
                      value={nationality}
                      onChange={handleNationalityChange}
                      options={nationalities}
                      renderInput={(params) => <TextField {...params} variant="standard" />}
                    />
                  </Grid>
                  <Grid item xs={122} sm={5} md={4} lg={2} xl={2}>
                    <div className={`label ${formValidation.gender ? formValidation.gender : ""}`}>Gender *</div>
                    <TextField select variant="standard" name="gender" value={gender || ""} onChange={handleChange} fullWidth>
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                    <div className={`label ${formValidation && formValidation.maritalstatus ? formValidation.maritalstatus : ""}`}>Martial Status *</div>
                    <TextField select variant="standard" name="maritalstatus" value={maritalstatus} onChange={handleChange} fullWidth>
                      <MenuItem value="Single">Single</MenuItem>
                      <MenuItem value="Married">Married</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                    <div className="label">Religion</div>
                    <TextField variant="standard" name="religion" value={religion} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={8} xl={4}>
                    <div className="label">Remarks</div>
                    <TextField variant="standard" name="remarks" value={remarks} onChange={handleChange} fullWidth />
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={12} lg={8} xl={4}>
                    <div className="label">Upload Photo</div>
                    <TextField type="file" variant="standard" name="photoloc" onChange={handleFileChange} fullWidth />
                  </Grid>
                  <Grid item xs={9} sm={10} md={8} lg={1} xl={1}>
                    {folderid && uploadedImage?.length > 0 && (
                      <Link to={`https://fmapi.fintrekmarketing.com:2083/uploads/${folderid}/${uploadedImage}`} target="_blank">
                        <Avatar
                          alt="Profile Photo"
                          src={`https://fmapi.fintrekmarketing.com:2083/uploads/${folderid}/${uploadedImage}`}
                          sx={{ width: 100, height: 100, borderRadius: 0 }}
                        />
                      </Link>
                    )}
                  </Grid> */}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* row 2 */}
        <Box className="itemBox">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="itemPaper">
                <h3 className="cardTitle">PASSPORT & VISA INFORMATION</h3>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Passport No.</div>
                    <TextField variant="standard" name="passportno" value={passportno} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Passport Status</div>
                    <TextField select variant="standard" name="passportstatus" value={passportstatus} onChange={handleChange} fullWidth>
                      <MenuItem value="With Company">With Company</MenuItem>
                      <MenuItem value="Employee">Employee</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Passport Expiry</div>
                    <ResponsiveDatePicker
                      name="passportexpiry"
                      className="employeeForm"
                      defValue={passportexpiry}
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Sponsor Name</div>
                    <TextField variant="standard" name="sponsorname" value={sponsorname} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Labour Card</div>
                    <TextField variant="standard" name="labourcard" value={labourcard} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Contract Type</div>
                    <TextField select variant="standard" name="contracttype" value={contracttype} onChange={handleChange} fullWidth>
                      <MenuItem value="Limited">Limited</MenuItem>
                      <MenuItem value="Labour Card">Labour Card</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Visa File No.</div>
                    <TextField variant="standard" name="visafileno" value={visafileno} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">UID</div>
                    <TextField variant="standard" name="visaid" value={visaid} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Visa Designation</div>
                    <TextField variant="standard" name="visadesignation" value={visadesignation} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Visa Status</div>
                    <TextField select variant="standard" name="visatype" value={visatype} onChange={handleChange} fullWidth>
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Inactive">Inactive</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Visa Expiry</div>
                    <ResponsiveDatePicker
                      name="visaexpiry"
                      className="employeeForm"
                      defValue={visaexpiry}
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Visa Cancellation Date</div>
                    <ResponsiveDatePicker
                      name="visacancellation"
                      className="employeeForm"
                      defValue={visacancellation}
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Emirates ID No.</div>
                    <TextField variant="standard" name="eidno" value={eidno} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Work Permit No.</div>
                    <TextField variant="standard" name="workpermitnumber" value={workpermitnumber} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Work Permit Start Date</div>
                    <ResponsiveDatePicker
                      name="workpermitstartdate"
                      className="employeeForm"
                      defValue={workpermitstartdate}
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Work Permit Expiry</div>
                    <ResponsiveDatePicker
                      name="workpermitexpiry"
                      className="employeeForm"
                      defValue={workpermitexpiry}
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Personal Code (MOHRE)</div>
                    <TextField variant="standard" name="personalcodemohre" value={personalcodemohre} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Medical Insurance Provider</div>
                    <TextField variant="standard" name="medicalprovider" value={medicalprovider} onChange={handleChange} fullWidth>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Medical Insurance Card No.</div>
                    <TextField variant="standard" name="medicalcardno" value={medicalcardno} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Medical Insurance Expiry</div>
                    <ResponsiveDatePicker
                      name="medicalexpiry"
                      className="employeeForm"
                      defValue={medicalexpiry}
                      handleDate={handleChange}
                    />
                  </Grid>
                  {/* Additional fields */}
                  {formValue.nationality === "U.A.E" && (
                    <>
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <div className="label">GPSSA</div>
                        <TextField select variant="standard" name="gpssa" value={gpssa} onChange={handleChange} fullWidth>
                          <MenuItem value="123">123</MenuItem>
                          <MenuItem value="456">456</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <div className="label">Insured No.</div>
                        <TextField
                          variant="standard"
                          name="insuredno"
                          value={insuredno}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <div className="label">Insured Date</div>
                        <ResponsiveDatePicker
                          name="insureddate"
                          className="employeeForm"
                          defValue={insureddate}
                          handleDate={handleChange}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* row 3 */}
        <Box className="itemBox">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="itemPaper">
                <h3 className="cardTitle">EMPLOYMENT DETAILS</h3>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Date of Join (Actual)</div>
                    <ResponsiveDatePicker
                      name="dateofjoin"
                      className="employeeForm"
                      defValue={dateofjoin}
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">MOL Start Date</div>
                    <ResponsiveDatePicker
                      name="dateofjoinmol"
                      className="employeeForm"
                      defValue={dateofjoinmol}
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Hired from</div>
                    <TextField select variant="standard" name="hiredfrom" value={hiredfrom} onChange={handleChange} fullWidth>
                      <MenuItem value="Outside">Outside</MenuItem>
                      <MenuItem value="Inside">Inside</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Office Location</div>
                    <TextField variant="standard" name="officeloc" value={officeloc} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Designation</div>
                    <TextField variant="standard" name="designation" value={designation} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Department</div>
                    <TextField select variant="standard" name="dept" value={dept} onChange={handleChange} fullWidth>
                      <MenuItem value="General">General</MenuItem>
                      <MenuItem value="Commercial Bank of Dubai (CBD)">Commercial Bank of Dubai (CBD)</MenuItem>
                      <MenuItem value="Dubai Islamic Bank (DIB)">Dubai Islamic Bank (DIB)</MenuItem>
                      <MenuItem value="Du">Du</MenuItem>
                      <MenuItem value="EIS">EIS</MenuItem>
                      <MenuItem value="Emirates National Bank of Dubai (ENBD)">Emirates National Bank of Dubai (ENBD)</MenuItem>
                      <MenuItem value="Hongkong and Shanghai Banking Corporation (HSBC)">Hongkong and Shanghai Banking Corporation (HSBC)</MenuItem>
                      <MenuItem value="Mashreq">Mashreq</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Division</div>
                    <TextField select variant="standard" name="division" value={division} onChange={handleChange} fullWidth>
                      <MenuItem value="Cards">Cards</MenuItem>
                      <MenuItem value="Loans">Loans</MenuItem>
                      <MenuItem value="Auto Loan">Auto Loan</MenuItem>
                      <MenuItem value="SME">SME</MenuItem>
                      <MenuItem value="Mortgage Loan">Mortgage Loan</MenuItem>
                      <MenuItem value="Admin">Admin</MenuItem>
                      <MenuItem value="IT">IT</MenuItem>
                      <MenuItem value="Accounts">Accounts</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <div className="label">Line Manager</div>
                    <TextField
                      select
                      variant="standard"
                      name="linemanager"
                      value={linemanager}
                      onChange={handleChange}
                      fullWidth
                    >
                      {lineManagers.map((manager) => (
                        <MenuItem key={manager.empid} value={manager.empid}>
                          {manager.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <div className="label">Team Leader</div>
                    <TextField variant="standard" name="teamleader" value={teamleader} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Source Code</div>
                    <TextField variant="standard" name="sourcecode" value={sourcecode} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Bank Name</div>
                    <TextField variant="standard" name="bankname" value={bankname} onChange={handleChange} fullWidth />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* row 4 */}
        <Box className="itemBox">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="itemPaper">
                <h3 className="cardTitle">CONTACT DETAILS</h3>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <div className="label">Contact No. (Home Country)</div>
                    <TextField variant="standard" name="contacthome" value={contacthome} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <div className="label">Emergency Contact Name (UAE)</div>
                    <TextField variant="standard" name="emergencycontactuaename" value={emergencycontactuaename} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <div className="label">Emergency Contact No. (UAE)</div>
                    <TextField variant="standard" name="emergencycontactuaeno" value={emergencycontactuaeno} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <div className="label">Emergency Contact Name (Home Country)</div>
                    <TextField variant="standard" name="emergencycontacthomename" value={emergencycontacthomename} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <div className="label">Emergency Contact No. (Home Country)</div>
                    <TextField variant="standard" name="emergencycontacthomeno" value={emergencycontacthomeno} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <div className="label">Company SIM No.</div>
                    <TextField variant="standard" name="companysimnumber" value={companysimnumber} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">SIM Issue Date</div>
                    <ResponsiveDatePicker
                      name="simissuedate"
                      className="employeeForm"
                      defValue={simissuedate}
                      variant="standard"
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">SIM Last Date</div>
                    <ResponsiveDatePicker
                      name="simlastdate"
                      className="employeeForm"
                      defValue={simlastdate}
                      variant="standard"
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">SIM Plan</div>
                    <TextField variant="standard" name="simplan" value={simplan} onChange={handleChange} fullWidth />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* row 5 */}
        <Box className="itemBox">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="itemPaper">
                <h3 className="cardTitle">
                  SALARY DETAILS
                  <span className="updatebtn" style={{ paddingLeft: "20px" }}>
                    <Button onClick={incrementContract} disabled={loader} style={{ width: "150px", borderRadius: "20px" }}>
                      + Add Contract
                    </Button>
                  </span>
                </h3>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                    <div className="label">Basic Salary</div>
                    <TextField variant="standard" name="basicsalary" value={basicsalary} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                    <div className="label">Housing Allowance</div>
                    <TextField variant="standard" name="housingallowance" value={housingallowance} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                    <div className="label">Transport Allowance</div>
                    <TextField variant="standard" name="transportallowance" value={transportallowance} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                    <div className="label">Food Allowance</div>
                    <TextField variant="standard" name="foodallowance" value={foodallowance} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                    <div className="label">Mobile Allowance</div>
                    <TextField variant="standard" name="mobileallowance" value={mobileallowance} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                    <div className="label">Other Allowance</div>
                    <TextField variant="standard" name="otherallowance" value={otherallowance} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                    <div className="label">Total Salary</div>
                    <TextField variant="standard" name="totalsalary" value={totalsalary} onChange={handleChange} onBlur={handleBlur} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                    <div className="label">Contract Salary</div>
                    <TextField variant="standard" name="contractsalary" value={contractsalary} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                    <div className="label">Account No.</div>
                    <TextField variant="standard" name="accnumber" value={accnumber} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <div className="label">IBAN</div>
                    <TextField variant="standard" name="iban" value={iban} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <div className="label">Salary Bank Name</div>
                    <TextField variant="standard" name="salarybankname" value={salarybankname} onChange={handleChange} fullWidth />
                  </Grid>

                  {/* Contracts */}
                  {formValue.contractupdate.map((contract, index) => (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                      <Paper className="position-relative">
                        <div className="closeButton" onClick={() => handleContractRemove(index)}>
                          x
                        </div>
                        <h3>Contract No. {index + 2}</h3>
                        <Grid container spacing={2}>
                          <Grid className="w-100" style={{ padding: 20 }} item xs={12} sm={6} md={3} lg={2} xl={2}>
                            <div className="label">Contract Date</div>
                            <ResponsiveDatePicker
                              variant="standard"
                              className="employeeForm"
                              name={`contractupdate${index}.date`}
                              defValue={contract.date}
                              handleDate={handleContractUpdate(index)}
                              fullWidth
                            />
                          </Grid>
                          <Grid className="w-100" style={{ padding: 20 }} item xs={12} sm={6} md={3} lg={2} xl={2}>
                            <div className="label">Basic Salary</div>
                            <TextField
                              variant="standard"
                              name={`contractupdate${index}.basic`}
                              value={contract.basic}
                              onChange={handleContractUpdate(index)}
                              fullWidth
                            />
                          </Grid>
                          <Grid className="w-100" style={{ padding: 20 }} item xs={12} sm={6} md={3} lg={2} xl={2}>
                            <div className="label">Housing Allowance</div>
                            <TextField
                              variant="standard"
                              name={`contractupdate${index}.housing`}
                              value={contract.housing}
                              onChange={handleContractUpdate(index)}
                              fullWidth
                            />
                          </Grid>
                          <Grid className="w-100" style={{ padding: 20 }} item xs={12} sm={6} md={3} lg={2} xl={2}>
                            <div className="label">Transport Allowance</div>
                            <TextField
                              variant="standard"
                              name={`contractupdate${index}.transport`}
                              value={contract.transport}
                              onChange={handleContractUpdate(index)}
                              fullWidth
                            />
                          </Grid>
                          <Grid className="w-100" style={{ padding: 20 }} item xs={12} sm={6} md={3} lg={2} xl={2}>
                            <div className="label">Food Allowance</div>
                            <TextField
                              variant="standard"
                              name={`contractupdate${index}.food`}
                              value={contract.food}
                              onChange={handleContractUpdate(index)}
                              fullWidth
                            />
                          </Grid>
                          <Grid className="w-100" style={{ padding: 20 }} item xs={12} sm={6} md={3} lg={2} xl={2}>
                            <div className="label">Mobile Allowance</div>
                            <TextField
                              variant="standard"
                              name={`contractupdate${index}.mobile`}
                              value={contract.mobile}
                              onChange={handleContractUpdate(index)}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid className="w-100" style={{ padding: 20 }} item xs={12} sm={6} md={3} lg={2} xl={2}>
                            <div className="label">Other Allowance</div>
                            <TextField
                              variant="standard"
                              name={`contractupdate${index}.allowance`}
                              value={contract.allowance}
                              onChange={handleContractUpdate(index)}
                              fullWidth
                            />
                          </Grid>
                          <Grid className="w-100" style={{ padding: 20 }} item xs={12} sm={6} md={3} lg={2} xl={2}>
                            <div className="label">Total Salary</div>
                            <TextField
                              variant="standard"
                              name={`contractupdate${index}.total`}
                              value={contract.total}
                              onChange={handleContractUpdate(index)}
                              fullWidth
                            />
                          </Grid>
                          <Grid className="w-100" style={{ padding: 20 }} item xs={12} sm={6} md={3} lg={2} xl={2}>
                            <div className="label">Contract Salary</div>
                            <TextField
                              variant="standard"
                              name={`contractupdate${index}.contracts`}
                              value={contract.contracts}
                              onChange={handleContractUpdate(index)}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* row 6 */}
        <Box className="itemBox">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={6}>
              <Paper sx={{ height: "400px" }} className="itemPaper">
                <h3 className="cardTitle">DOCUMENT UPLOADS</h3>
                <DragNDrop onDrop={handleDrop} uploadedFiles={tempUploadedFiles} setUploadedFiles={setTempUploadedDocs} handleRemoveFile={handleRemoveFile} />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={6}>
              <Paper className="itemPaper" sx={{
                height: "400px",
                padding: "30px",
                overflow: "hidden"
              }}>
                <h3 className="cardTitle" style={{ margin: "0", padding: "0" }}>EXISTING DOCUMENTS</h3>
                <TableWrapper sx={{ overflowY: "scroll", height: "300px" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>File Name</TableCell>
                        <TableCell style={{ textAlign: "right" }}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {uploadedDocs && uploadedDocs.length > 0 && uploadedDocs.map((doc, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ margin: "0", padding: "0 16px" }}>
                            {folderid && (
                              <Link to={`https://fmapi.fintrekmarketing.com:2083/uploads/${folderid}/${doc.filename}`} target="_blank" style={{ color: "black", textDecoration: "none" }}>
                                {doc.filename}
                              </Link>
                            )}
                          </TableCell>
                          <TableCell style={{ margin: "0", padding: "0 16px", textAlign: "right" }}>
                            {folderid && (
                              <IconButton style={{ color: "#2596BE" }} onClick={() => handleDeleteDocument(doc.filename)}>
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableWrapper>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* row 7 */}
        <Box className="itemBox">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="itemPaper requiredDocs">
                <h3 className="cardTitle">REQUIRED DOCUMENTS</h3>
                <ul className="requiredDocsList">
                  {requiredDocuments.map((docKey) => {
                    const doc = documentMappingInitial[docKey]
                    const docName = doc?.name || docKey
                    const isUploaded = isDocumentUploaded(docName)
                    return (
                      <li className="requiredDocsItem" key={docName}>
                        <div className="textContainer">
                          {isUploaded ? (
                            <CheckCircleIcon style={{ color: "green", marginRight: "8px" }} className="docIcon" />
                          ) : (
                            <Box
                              className="docIcon"
                              sx={{
                                width: 24,
                                height: 24,
                                border: "1px solid #ccc",
                                borderRadius: "50%",
                                marginRight: "8px",
                              }}
                            />
                          )}
                          {docName}
                        </div>
                        <Button
                          className="removeButton"
                          onClick={() => removeRequiredDocument(docKey)}
                        >
                          Remove
                        </Button>
                      </li>
                    )
                  })}
                </ul>
                <Box className="addDocBox">
                  <TextField
                    variant="standard"
                    placeholder="Add new document"
                    value={newDoc}
                    onChange={(e) => setNewDoc(e.target.value)}
                    sx={{ marginRight: "8px", flexGrow: 1 }}
                    inputProps={{ style: { textTransform: "none" } }}
                  />
                  <Button className="add-list" onClick={() => addRequiredDocument(newDoc)}>
                    Add
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <Box className="itemBox" style={{ display: "flex", gap: "20px" }}>
          <div className="updatebtn" style={{ textAlign: "center" }}>
            <Button
              onClick={handleSubmit}
              disabled={loader}
              style={{
                backgroundColor: "#1976d2",
                color: "white",
              }}
            >
              {loader ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) : (
                "SAVE CHANGES"
              )}
            </Button>
          </div>
        </Box>

      </Box >

      {/* Salary Confirmation Modal */}
      <Modal open={confirmationModal} onClose={handleCancelChange} aria-labelledby="confirmation-modal-title" aria-describedby="confirmation-modal-description">
        <Paper className="floatingPaper">
          <Typography id="confirmation-modal-title" variant="h6" component="h2">
            Confirm Salary Change
          </Typography>
          <Typography id="confirmation-modal-description" sx={{ mt: 2, color: "gray" }}>
            Are you sure you want to change the total salary?
          </Typography>
          <div style={{ display: "flex", marginTop: "20px", width: "370px", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancelChange}
              style={{ backgroundColor: "#FF0000", color: "white", width: "60px", height: "35px" }}>
              No
            </Button>
            <Button
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
                width: "60px",
                height: "35px",
              }}
              autoFocus
              onClick={handleConfirmChange}>
              Yes
            </Button>
          </div>
        </Paper>
      </Modal>

      {/* Overwrite Modal */}
      <Modal open={overwriteModal} onClose={closeOverwiteModal} aria-labelledby="confirmation-modal-title" aria-describedby="confirmation-modal-description">
        <Paper className="floatingPaper">
          <Typography id="confirmation-modal-title" variant="h6" component="h2">
            Confirm Overwrite?
          </Typography>
          <Typography id="confirmation-modal-description" sx={{ mt: 2, color: "gray" }}>
            One or more file(s) that you are trying to upload already exists. Are you sure you want to overwrite them?
          </Typography>
          <div style={{ display: "flex", marginTop: "10px", width: "370px", justifyContent: "space-between" }}>
            <Button variant="contained" onClick={closeOverwiteModal} style={{ backgroundColor: "#FF0000", color: "white", width: "60px", height: "35px" }}>
              No
            </Button>
            <Button
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
                width: "60px",
                height: "35px",
              }}
              onClick={() => { handleFinalSubmit(); closeOverwiteModal(); }}
              autoFocus
            >
              Yes
            </Button>
          </div>
        </Paper>
      </Modal>
    </>
  )
}