import React from "react"
import UserProfileForm from "../../Components/Forms/UserProfileForm"

function UserProfile() {
  return (
    <>
      <h1>User Profile</h1>
      <UserProfileForm />
    </>
  )
}

export default UserProfile
