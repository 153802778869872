import React from "react"
import SalaryCertificateRequestsTable from "../../Components/Tables/SalaryCertTables/SalaryCertificateRequestsTable"

function salaryCertificateRequests() {
  return (
    <div>
      <SalaryCertificateRequestsTable/>
    </div>
  )
}

export default salaryCertificateRequests
