import React from "react"

const Error404 = () => {
    return(
        <div className="errorPage">
            <div className="text-error">
                Error 404 
            </div>
            <h2>
                Page not found
            </h2>
            <p>
                It's looking like you may have taken a wrong turn. Don't worry... it
                happens to the best of us!
            </p>
        </div>
    )
}

export default Error404