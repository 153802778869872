import React from "react"
import AddEmployeeForm from "../../Components/Forms/AddEmployeeForm"

function AddNewEmployee ( onSearch ) {
  return (
    <>
      <h1>Add New Employee</h1>
      <AddEmployeeForm />
    </>
  )
}

export default AddNewEmployee
