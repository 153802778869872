import React from 'react'
import Sidebar from '../../Components/sideBar'

function PayrollReports() {
  return (
    <h1>
      Payroll Report
    </h1>
  )
}

export default PayrollReports
