import React from "react"
import ChangePasswordForm from "../../Components/Forms/ChangePasswordForm"

function ChangePassword() {
    return (
        <div style={{
            paddingTop: "70px",
            paddingBottom: "20px",
        }}>
            <ChangePasswordForm />
        </div>
    )
}

export default ChangePassword
