import { saveAs } from "file-saver"
import html2pdf from "html2pdf.js"
// import jsPDF from "jspdf"
// import html2canvas from "html2canvas"
// import htmlToDocx from "html-to-docx"
import fintrekMarketingConsultanciesLLCLetterhead from "../../assets/salaryCertifTemplates/fintrekMarketingConsultanciesLLCLetterhead.png"
import fintrekMarketingConsultancyLetterhead from "../../assets/salaryCertifTemplates/fintrekMarketingConsultancyLetterhead.png"
import fintrekMarketingLetterhead from "../../assets/salaryCertifTemplates/fintrekMarketingLetterhead.png"
import neowizMarketingManagementLetterhead from "../../assets/salaryCertifTemplates/neowizMarketingManagementLetterhead.png"
import wizhullLetterhead from "../../assets/salaryCertifTemplates/wizhullLetterhead.png"
import { toast } from "react-toastify"

const refNumberTracker = {}

const generateRefNo = (sponsorname) => {
  const prefixMap = {
    "Fintrek Marketing": "FM",
    "Fintrek Marketing Consultancy": "FMC",
    "Fintrek Marketing Consultancies LLC": "FMCLLC",
    "Neowiz Marketing Management": "NMM",
    "Wizhull Real Estate Mortgage Broker": "WREMB",
  }

  const prefix = prefixMap[sponsorname] || "FM"
  const date = new Date()
  const formattedDate = `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getFullYear()).slice(-2)}`

  if (!refNumberTracker[sponsorname]) {
    refNumberTracker[sponsorname] = 1
  } else {
    refNumberTracker[sponsorname]++
  }

  const uniqueNumber = String(refNumberTracker[sponsorname]).padStart(2, '0')

  return `${prefix}/${formattedDate}/${uniqueNumber}`
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const getLetterHead = (sponsorname) => {
  switch (sponsorname) {
    case "Fintrek Marketing":
      return fintrekMarketingLetterhead
    case "Fintrek Marketing Consultancy":
      return fintrekMarketingConsultancyLetterhead
    case "Fintrek Marketing Consultancies LLC":
      return fintrekMarketingConsultanciesLLCLetterhead
    case "Neowiz Marketing Management":
      return neowizMarketingManagementLetterhead
    case "Wizhull Real Estate Mortgage Broker":
      return wizhullLetterhead
    default:
      return fintrekMarketingLetterhead
  }
}

export const GenerateExperienceLetter = async (employee, today, title, pronoun1, pronoun2, sponsorname, format) => {

  try {
    const letterhead = getLetterHead(employee.sponsorname)
    const refNo = generateRefNo(employee.sponsorname)

    const content = `
    <html>
        <body>
            <div id="experience-letter-content" style="position: relative; font-family: 'Times', serif; font-size: 13pt; line-height: 1.5; width: 210mm; height: 297mm;">
            <!-- Background Letterhead -->
        
                <img src="${letterhead}" alt="Header" style="position: absolute; width: 210mm; height: 297mm; top: 0; left: 0; z-index: 0;">

                <!-- Content on top of the Letterhead -->
                <div style="position: relative; z-index: 1; padding-top: 50mm; padding-left: 20mm; padding-right: 20mm; padding-bottom: 40mm;">
                    <div style="margin-bottom: 20px; text-align: left">
                        <p style="margin: 0; font-family: 'Calibri', sans-serif; font-size: 11pt;">
                            <strong>Ref. No: ${refNo}</strong>
                        </p>
                        <p style="margin: 0; font-family: 'Calibri', sans-serif; font-size: 11pt;">
                            <strong>Date: ${today}</strong>
                        </p>
                    </div>
                    
                    <h2 style="text-align: center; font-family: 'Times', serif; font-size: 13pt; font-weight: bold; margin-top: 20px;">
                        EXPERIENCE CERTIFICATE
                    </h2>

                    <p style="font-family: 'Times', serif; font-size: 13pt; margin-top: 20px; text-align: justify;">
                        This is to certify that <strong>${title} ${employee.name}</strong> 
                        holder of <strong>${employee.nationality}</strong> passport number
                        <strong>${employee.passportno}</strong> was employed by ${employee.sponsorname} 
                        from <strong>${employee.dateofjoin}</strong> to <strong>${employee.lwd}</strong>.
                        ${capitalizeFirstLetter(pronoun1)} last job position with the company was ${employee.designation}.
                    </p>

                    <p style="font-family: 'Times', serif; font-size: 13pt; margin-top: 20px; text-align: justify;">
                        We take this opportunity of wishing ${pronoun2} all success in ${pronoun1} future careers.
                    </p>
                    
                    <p style="font-family: 'Times', serif; font-size: 13pt; margin-top: 20px; text-align: justify;">
                        This certificate is issued upon ${pronoun1} request.
                    </p>

                    <p style="margin-top: 20px;">Yours Sincerely,</p>
                    <p style="margin-top: 70px;">
                        Human Resources Department<br>
                        ${employee.sponsorname}
                    </p>
                </div>
            </div>
        </body>
    </html>
    `

    if (format === "pdf") {
      const element = document.createElement('div')
      element.innerHTML = content

      html2pdf()
        .from(element)
        .set({
          margin: 0,
          filename: `${employee.name}_Experience_Letter.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        })
        .save()
        .then(() => {
          document.body.removeChild(element)
        })
        .catch(error => {
          console.error('Error generating PDF:', error)
        })
    } else if (format === "word") {
      toast.error("This feature is not available right now!")
    }
  } catch (error) {
    console.error("Error in GenerateExperienceLetter:", error)
  }
}