import React from "react"
import InactiveEmployeeTable from "../../Components/Tables/EmployeeTables/InactiveEmployeeTable"

function InactiveEmployee ( ) {
  return (
    <InactiveEmployeeTable/>
  )
}

export default InactiveEmployee
