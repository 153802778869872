import React from 'react'
import Sidebar from '../../Components/sideBar'

function CandidateDatabase() {
  return (
    <h1>
      Candidate DB
    </h1>
  )
}

export default CandidateDatabase
