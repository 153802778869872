import React from "react"
import LeaveRequestForm from "../../Components/Forms/LeaveRequestForm"

export default function LeaveRequest() {

  return (
    <div style={{
      paddingTop: "60px",
      paddingBottom: "20px",
    }}>
      <LeaveRequestForm />
    </div>
  )
}
