import React from 'react'
import LeaveApplicationsTable from "../../Components/Tables/LeaveMgmtTables/LeaveApplicationsTable"

function LeaveApplications() {
  return (
    <div>
      <LeaveApplicationsTable/>
    </div>
  )
}

export default LeaveApplications
