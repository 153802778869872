import React from "react"
import ManageEmployeeTable from "../../Components/Tables/EmployeeTables/ManageEmployeeTable"

function ManageEmployee() {
    return (
        <ManageEmployeeTable />
    )
}

export default ManageEmployee
