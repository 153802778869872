import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Modal, CircularProgress, Divider } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import DescriptionIcon from "@mui/icons-material/Description"
import ImageIcon from "@mui/icons-material/Image"
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile"
import { fetchEmpDocumentDetails } from "../../Actions/commonController"

const getFileIcon = (filename) => {
  const extension = filename.split('.').pop().toLowerCase()
  switch (extension) {
    case 'pdf':
      return <PictureAsPdfIcon />
    case 'doc':
    case 'docx':
      return <DescriptionIcon />
    case 'png':
    case 'jpg':
    case 'jpeg':
      return <ImageIcon />
    default:
      return <InsertDriveFileIcon />
  }
}

export default function DocumentDetailsModal({ open, onClose, empId }) {
  const [loader, setLoader] = useState(false)
  const [documentDetails, setDocumentDetails] = useState(null)
  const [documents, setDocuments] = useState([])

  const fetchData = async () => {
    try {
      let APIData = { empid: empId }
      if (empId) {
        setLoader(true)
        let { status, data, docs } = await fetchEmpDocumentDetails(APIData)
        if (status === "success") {
          setDocumentDetails(data)
          setDocuments(docs)
        }
      }
    } catch (error) {
      console.error("Error fetching personal details data:", error)
    }
    setLoader(false)
  }

  useEffect(() => {
    if (open) fetchData()
  }, [empId, open])

  return (
    <Modal
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      open={open}
      onClose={onClose}
      className="modal"
      keepMounted
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 id="keep-mounted-modal-title" className="name">
            Document Details
          </h2>
          <button
            type="button"
            onClick={onClose}
            className="close-icon"
          >
            <CloseIcon />
          </button>
        </div>
        <Divider className="divider" />
        <div className="modalBody">
          {loader ? (
            <CircularProgress size={24} style={{ color: "black" }} />
          ) : (
            documents && documents.length > 0 ? (
              documents.map((doc, index) => (
                <div key={index} className="document-item">
                  {getFileIcon(doc.filename)}
                  <a
                    href={`https://fmapi.fintrekmarketing.com:2083/uploads/${documentDetails?.folderid}/${doc.filename}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "black", textDecoration: "none", marginLeft: "8px" }}
                  >
                    {doc.filename}
                  </a>
                </div>
              ))
            ) : (
              <p>No documents found.</p>
            )
          )}
        </div>
      </div>
    </Modal>
  )
}

DocumentDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  empId: PropTypes.string.isRequired,
}