import React from 'react'
import Sidebar from '../../Components/sideBar'

function RecruitmentReports() {
  return (
    <h1>
      Recruitment Reports
    </h1>
  )
}

export default RecruitmentReports
