import React, { useState, useEffect } from "react"
import {
  CircularProgress,
  TextField,
  Typography,
  Button,
  Stack,
  useTheme
} from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import { toast } from "react-toastify"
import { submitLeaveApplication, fetchExistingLeaveBalance } from "../../Actions/commonController"
import { useAuth } from "../../Context/authContext"
import ResponsiveDatePicker from "../DatePicker/ResponsiveDatePicker"
import { useNavigate } from "react-router-dom"

const initialValue = {
  leaveType: "",
  leaveBalance: {},
  startDate: "",
  endDate: "",
  reason: "",
  duration: "",
  dayLength: ""
}

export default function LeaveRequestForm() {
  const [formValue, setFormValue] = useState(initialValue)
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const { eid } = useAuth()
  const navigate = useNavigate()

  const {
    leaveType,
    leaveBalance,
    startDate,
    endDate,
    reason,
    dayLength
  } = formValue

  // const fetchLeaveBalanceData = async () => {
  //   try {
  //     let APIData = {
  //       empid: eid,
  //     }
  //     const response = await fetchExistingLeaveBalance(APIData)
  //     if (response.status === "success") {
  //       const data = response.data
  //       setFormValue(prevFormValue => ({
  //         ...prevFormValue,
  //         leaveBalance: data,
  //       }))
  //     } else {
  //       console.error("Failed to fetch leave balance:", response)
  //     }
  //   } catch (error) {
  //     console.error("Error fetching leave balance:", error)
  //   }
  // }

  // useEffect(() => {
  //   fetchLeaveBalanceData()
  // }, [eid])

  const handleLeaveTypeChange = (selectedLeaveType) => {
    setFormValue(prevFormValue => ({
      ...prevFormValue,
      leaveType: selectedLeaveType
    }))
  }

  const handleDurationTypeChange = (selectedDurationType) => {
    setFormValue(prevFormValue => ({
      ...prevFormValue,
      dayLength: selectedDurationType
    }))
  }

  const handleChange = (e) => {
    let { name, value } = e.target
    setFormValue({
      ...formValue,
      [name]: value
    })
  }

  const handleDateChange = ({ target: { name, value } }) => {
    setFormValue(prevFormValue => ({
      ...prevFormValue,
      [name]: value
    }))
  }

  const handleSubmit = async () => {

    if (startDate === "" || endDate === "") {
      toast.error(`One or more of the dates are missing. Kindly fill in the below fields.`)
      return
    }

    const startDateObj = new Date(startDate.split('/').reverse().join('/'))
    const endDateObj = new Date(endDate.split('/').reverse().join('/'))

    const formattedStartDate = startDateObj.toLocaleDateString('en-CA')
    const formattedEndDate = endDateObj.toLocaleDateString('en-CA')

    const oneDay = 24 * 60 * 60 * 1000
    const duration = Math.round(Math.abs((endDateObj - startDateObj) / oneDay)) + 1

    if (duration > leaveBalance[leaveType]) {
      toast.error(`You cannot take more ${leaveType} leaves. There aren't enough ${leaveType} leaves left.`)
      return
    }

    if (startDateObj > endDateObj) {
      toast.error(`Seems like you mixed your leave dates, recheck them again!`)
      return
    }

    let APIData = {
      leaveType: leaveType,
      leaveBalance: leaveBalance,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      dayLength: dayLength,
      reason: reason,
      empid: eid
    }

    try {
      setLoading(true)
      let { status, message } = await submitLeaveApplication(APIData)
      if (status === "success") {
        toast.success("Leave application submitted successfully!")
        navigate('/leave/pending-leaves-list')
      }
      else toast.error("Unfortunately, you have run out of leaves that you can take!")
    } catch (err) {
      toast.error(err.message)
    }
    setLoading(false)
  }

  const leaveTypes = [
    "Annual",
    "Sick",
    // "Maternity",
    // "Unpaid"
  ]
  const durationOptions = ["Full Day", "Half Day (Morning): 9:00AM - 1:00PM", "Half Day (Afternoon): 2:00PM - 6:00PM"]

  return (
    <div style={{
      padding: "20px",
      margin: "auto",
      fontFamily: "Open Sans",
      borderRadius: "5px",
      width: "500px",
      boxShadow:
        "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
      backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff"
    }}>
      <Typography variant="h3" sx={{ marginBottom: 2 }}>LEAVE APPLICATION</Typography>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2} >
          <TextField
            select
            variant="outlined"
            name="leaveType"
            label="Leave Type"
            value={leaveType}
            onChange={(e) => handleLeaveTypeChange(e.target.value)}
            fullWidth
            sx={{ width: "200px" }}
          >
            {leaveTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
          {/* <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body1" sx={{ marginRight: "0px" }}>Balance:</Typography>
            {leaveType && leaveBalance && leaveBalance[leaveType.toLowerCase()] !== undefined && (
              <Typography variant="body1" sx={{ color: "green", fontWeight: "bold", marginRight: "0px" }}>
                {leaveBalance[leaveType.toLowerCase()]} days
              </Typography>
            )}
          </Stack> */}
        </Stack>
        <Stack direction="row" spacing={2} alignItems="flex-end">
          <ResponsiveDatePicker
            name="startDate"
            label="Start Date"
            defValue={startDate}
            handleDate={handleDateChange} />
          <ResponsiveDatePicker
            name="endDate"
            label="End Date"
            defValue={endDate}
            handleDate={handleDateChange} />
        </Stack>
        <TextField
          select
          variant="outlined"
          name="dayLength"
          label="Duration"
          value={dayLength}
          onChange={(e) => handleDurationTypeChange(e.target.value)}
          fullWidth
          sx={{ width: "460px" }}
        >
          {durationOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          variant="outlined"
          name="reason"
          label="Reason"
          value={reason}
          onChange={handleChange}
          fullWidth
          multiline
          rows={4}
        />
      </Stack>
      <div className="sbmtRequest"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px"
        }}
      >
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={loading}
          style={{ backgroundColor: theme.palette.primary.main }}
        >
          {loading ? (
            <CircularProgress size={24} style={{ color: "white" }} />
          ) : (
            "SUBMIT"
          )}
        </Button>
      </div>
    </div>
  )
}
