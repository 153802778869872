import React from 'react'

function Payroll() {
  return (
    <div>
      
    </div>
  )
}

export default Payroll
