import React, { useState, useEffect } from "react"
import {
    Modal,
    Box,
    Typography,
    CircularProgress,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
} from "@mui/material"
import PaginationTable from "../Pagination"
import { fetchSalaryCertifHistory } from "../../Actions/commonController"
import { FormatDate } from "../Utilities/DateFormatter"

const columns = [
    { id: "createdat", label: "REQUEST DATE", minWidth: 125 },
    { id: "status", label: "STATUS", minWidth: 100 },
    { id: "reason", label: "REASON", minWidth: 100 },
]

const SalaryCertificateHistoryModal = ({ open, onClose, empId }) => {
    const [page, setPage] = useState(1)
    const [quantity, setQuantity] = useState(10)
    const [totalItems, setTotalItems] = useState(0)
    const [certificateData, setCertificateData] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchCertificateData = async () => {
        setLoading(true)
        try {
            const APIData = {
                empid: empId,
                quantity,
                page,
            }
            const { data, status } = await fetchSalaryCertifHistory(APIData)
            if (status === "success") {
                setCertificateData(data)
                setTotalItems(data.length)
            } else {
                console.error("Error fetching salary certificates:", status)
            }
        } catch (error) {
            console.error("Error fetching salary certificates:", error)
        }
        setLoading(false)
    }

    const handlePageClick = (newPage) => {
        setPage(newPage)
    }

    const handleItemsPerPageChange = (newRowsPerPage) => {
        setQuantity(newRowsPerPage)
        setPage(1)
    }

    useEffect(() => {
        if (empId) {
            fetchCertificateData()
        }
    }, [empId, page, quantity])

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                className="salary-cert-modal"
                sx={{
                    width: "80%",
                    maxWidth: "800px",
                    backgroundColor: "white",
                    margin: "auto",
                    padding: "20px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    position: "absolute",
                    maxHeight: "90vh",
                    overflowY: "auto",
                    borderRadius: "10px",
                    boxShadow: 24,
                }}
            >
                <Typography id="modal-title" variant="h6" component="h2">
                    Salary Certificate History
                </Typography>
                {loading ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "300px",
                        }}
                    >
                        <CircularProgress size={50} />
                    </Box>
                ) : (
                    <TableContainer sx={{ paddingTop: "20px", overflowY: "auto" }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align="center"
                                            style={{
                                                minWidth: column.minWidth,
                                                fontWeight: "bold",
                                                fontSize: "12px",
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {certificateData && certificateData.length > 0 ? (
                                    certificateData.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">
                                                {FormatDate(item.createdat)}
                                            </TableCell>
                                            <TableCell align="center">
                                                {item.status}
                                            </TableCell>
                                            <TableCell align="center">
                                                {item.reason}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={4} align="center">
                                            No certificate data available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                <PaginationTable
                    totalItems={totalItems}
                    itemsPerPage={quantity}
                    onPageChange={handlePageClick}
                    onItemsPerPageChange={handleItemsPerPageChange}
                />
            </Box>
        </Modal>
    )
}

export default SalaryCertificateHistoryModal
