import React, { useState, useEffect } from "react"
import {
    CircularProgress,
    TextField,
    Typography,
    Button,
    useTheme
} from "@mui/material"
import { changePass } from "../../Actions/commonController"
import { useAuth } from "../../Context/authContext"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const initialValue = {
    newPassword: "",
    confirmPassword: ""
}

export default function ChangePasswordForm() {
    const [formValue, setFormValue] = useState(initialValue)
    const [loading, setLoading] = useState(false)
    const { eid } = useAuth()
    const theme = useTheme()
    const navigate = useNavigate()

    const {
        newPassword,
        confirmPassword
    } = formValue

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValue({
            ...formValue,
            [name]: value
        })
    }

    const handleSubmit = async () => {
        if (newPassword !== confirmPassword) {
            toast.error("New passwords do not match!")
            return
        } else if (newPassword.length <= 0) {
            toast.error("Password cannot be empty!")
            return
        }

        try {
            setLoading(true)
            let APIData = {
                newPassword,
                empid: eid
            }
            let { status, message } = await changePass(APIData)
            if (status === "success") {
                toast.success(message)
                setFormValue(initialValue)
            } else toast.error(message)
        } catch (error) {
            console.error("Error changing password:", error)
            toast.error("Failed to change password. (Contact Administrator)")
        } finally {
            setLoading(false)
        }
    }

    return (
        <div
            style={{
                maxWidth: "400px",
                margin: "auto",
                padding: "20px",
                fontFamily: "Open Sans",
                borderRadius: "5px",
                boxShadow:
                    "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
                backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff"
            }}
        >
            <Typography variant="h3" sx={{ marginBottom: 2 }}>
                CHANGE PASSWORD
            </Typography>
            <TextField
                type="password"
                variant="outlined"
                name="newPassword"
                label="New Password"
                value={newPassword}
                onChange={handleChange}
                fullWidth
                sx={{ marginBottom: 2 }}
            />
            <TextField
                type="password"
                variant="outlined"
                name="confirmPassword"
                label="Confirm Password"
                value={confirmPassword}
                onChange={handleChange}
                fullWidth
                sx={{ marginBottom: 2 }}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px"
                }}
            >
                <Button
                    autoFocus
                    onClick={handleSubmit}
                    disabled={loading}
                    sx={{
                        backgroundColor: 'primary.main',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: 'primary.dark',
                        },
                        width: '80px',
                        height: '35px',
                    }}
                >
                    {loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : "Submit"}
                </Button>
            </div>
        </div>
    )
}
